import React, { ReactElement } from 'react';

import Container, {
  ContainerProps,
} from '@amzn/awsui-components-react/polaris/container';

export interface iCardProps extends ContainerProps {}

export function Card(props: iCardProps): ReactElement {
  const { className, children } = props;
  return (
    <Container
      {...props}
      className=""
    >
      <div className={className}>{children}</div>
    </Container>
  );
}
