import { combineReducers } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import masterDataSlice from '@/library/storeSlices/masterData/masterDataSlice';
import overviewDataSlice from '@/library/storeSlices/overviewData/overviewDataSlice';
import scheduleWODataSlice from '@/library/storeSlices/scheduleWOData/scheduleWODataSlice';

export const rootReducer: Reducer = combineReducers({
  masterDataReducer: masterDataSlice,
  scheduleWODataReducer: scheduleWODataSlice,
  overviewDataReducer: overviewDataSlice,
});
