import React, { ReactElement } from 'react';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import { uiStrings } from '@/library/constants/uiStrings';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { ShiftOverwriteInput } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/ShiftOverwriteInput';

interface iShiftOverwriteInputListProps {
  woData: iFormattedRecommendationsData;
}

export function ShiftOverwriteInputList(
  props: iShiftOverwriteInputListProps,
): ReactElement {
  const { woData } = props;
  const { fetchAllShiftsError, shiftListToDateMap } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const shiftError =
    !!fetchAllShiftsError ||
    !woData.scheduledStartDate ||
    !shiftListToDateMap[woData.scheduledStartDate]?.length;

  const shiftErrorMessage = !woData.scheduledStartDate
    ? uiStrings.selectStartDateFirst
    : !shiftListToDateMap[woData.scheduledStartDate]?.length
    ? fetchAllShiftsError || uiStrings.noShiftFoundForDate
    : '';

  return (
    <div>
      <>
        <ShiftOverwriteInput
          woData={woData}
          shiftError={shiftError}
          shiftErrorMessage={shiftErrorMessage}
        />
        {woData.requiredTechniciansCount > 1 && (
          <div>
            {[...Array(woData.requiredTechniciansCount - 1).keys()].map(
              index => (
                <div
                  key={
                    (woData.secondaryOwnersShifts[index] ?? '') +
                    (woData.secondaryOwners?.[index] ?? '') +
                    index
                  }
                  className="additional-technician-shift-dropdown"
                >
                  <ShiftOverwriteInput
                    woData={woData}
                    secondaryShiftIndex={index}
                    shiftError={shiftError}
                    shiftErrorMessage={shiftErrorMessage}
                  />
                </div>
              ),
            )}
          </div>
        )}
      </>
    </div>
  );
}
