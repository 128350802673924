import React, { ReactElement } from 'react';

import PolarisSpinner, {
  SpinnerProps,
} from '@amzn/awsui-components-react/polaris/spinner';

export interface iSpinnerProps extends SpinnerProps {}

export function Spinner(props: iSpinnerProps): ReactElement {
  return <PolarisSpinner {...props} />;
}
