import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import OttoRoutes from '@/configs/routesConfig/OttoRoutes';
import { persistor, store } from '@/configs/storeConfig/storeConfig';
import ErrorBoundary from '@/library/components/atoms/ErrorBoundary';
import OttoMessageBox from '@/library/components/molecules/OttoMessageBox';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';

function RootComponent(): ReactElement {
  const handleOnError = (): void => {
    recordEvent(metricNames.otto_appCrash);
  };

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <ErrorBoundary
          onError={handleOnError}
          fallback={
            <div className="error-boundary-container">
              <div className="error-boundary-message">
                <OttoMessageBox
                  headingText={uiStrings.uhOh}
                  paragraphs={[
                    uiStrings.somethingWentWrong,
                    uiStrings.tryReloading,
                  ]}
                />
              </div>
            </div>
          }
        >
          <OttoRoutes />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default RootComponent;
