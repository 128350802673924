import React, { ReactElement } from 'react';

import PolarisIcon, {
  IconProps,
} from '@amzn/awsui-components-react/polaris/icon';

export interface iIconProps extends IconProps {}

export function Icon(props: iIconProps): ReactElement {
  return <PolarisIcon {...props} />;
}
