import React, { ReactElement } from 'react';

import Header, {
  HeaderProps,
} from '@amzn/awsui-components-react/polaris/header';

interface iHeaderTextProps extends HeaderProps {}

export function HeaderText(props: iHeaderTextProps): ReactElement {
  return <Header {...props} />;
}
