import React, { ReactElement } from 'react';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Link from '@/library/components/atoms/Link';
import { metricNames } from '@/library/constants/metricConstants';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { openAPMWorkOrderScreenPopUp } from '@/library/utils/extJSUtils';
import { recordClickEvent } from '@/library/utils/metricsUtils';

interface iWorkOrderIdProps {
  workOrderDetails: iFormattedRecommendationsData;
}

export function WorkOrderId(props: iWorkOrderIdProps): ReactElement {
  const { workOrderDetails } = props;

  const { woTypeToAPMScreenIdMapping } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const onClickWorkOrderID = (): void => {
    recordClickEvent(metricNames.swo_workOrderIdClick);
    openAPMWorkOrderScreenPopUp(workOrderDetails, woTypeToAPMScreenIdMapping);
  };

  return (
    <div onClick={onClickWorkOrderID}>
      <Link>{workOrderDetails.workOrderID}</Link>
    </div>
  );
}
