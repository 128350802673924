import React, { ReactElement } from 'react';

import PolarisPagination, {
  PaginationProps,
} from '@amzn/awsui-components-react/polaris/pagination';

interface iPaginationProps extends PaginationProps {}

export function Pagination(props: iPaginationProps): ReactElement {
  return <PolarisPagination {...props} />;
}
