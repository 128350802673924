import React, { ReactElement, useEffect, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Banner, { iMessageDefinition } from '@/library/components/atoms/Banner';
import Spinner from '@/library/components/atoms/Spinner';
import OttoMessageBox from '@/library/components/molecules/OttoMessageBox';
import { uiStrings } from '@/library/constants/uiStrings';
import { fetchForecastedData } from '@/library/storeSlices/overviewData/overviewDataActions';

import OverviewComparisonChart from './overviewFrames/OverviewComparisonChart';
import OverviewContentSection from './overviewFrames/OverviewContentSection';

import './overviewStyles.scss';

interface iForecastedDataError {
  status?: number;
  message?: string;
  error?: boolean;
}

function Overview(): ReactElement {
  const [fetchingForecastedData, setFetchingForecastedData] =
    useState<boolean>(false);
  const [forecastedDataError, setForecastedDataError] =
    useState<iForecastedDataError>({});

  const [items, setItems] = useState<iMessageDefinition[]>([
    {
      type: 'error',
      content: uiStrings.forecastedDataError,
      dismissible: true,
      onDismiss: () => setItems([]),
      dismissLabel: 'Dismiss message',
    },
  ]);

  const dispatch = useAppDispatch();

  const {
    sectionizedListOfSites,
    activeSite,
    isFetchingOttoSites,
    isFetchingUserAuthorisedSites,
  } = useAppSelector(state => state.masterDataReducer);

  useEffect(() => {
    if (
      sectionizedListOfSites?.ottoEnabledUserAuthorizedSites.length &&
      activeSite?.siteId &&
      !fetchingForecastedData
    ) {
      setFetchingForecastedData(true);
      dispatch(fetchForecastedData(activeSite))
        .unwrap()
        .then(() => setForecastedDataError({ error: false }))
        .catch(err => setForecastedDataError({ ...err, error: true }))
        .finally(() => setFetchingForecastedData(false));
    }
  }, [activeSite, sectionizedListOfSites]);

  const overviewSpinner = (message): ReactElement => (
    <div className="overview-data-loader-container">
      <Spinner size="large" />
      {message}
    </div>
  );

  return sectionizedListOfSites &&
    !isFetchingOttoSites &&
    !isFetchingUserAuthorisedSites ? (
    sectionizedListOfSites.ottoEnabledUserAuthorizedSites.length ? (
      fetchingForecastedData || !activeSite?.siteId ? (
        overviewSpinner(uiStrings.fetchingUtilisationData)
      ) : forecastedDataError.error ? (
        <Banner items={items} />
      ) : (
        <>
          <OverviewContentSection />
          <OverviewComparisonChart />
        </>
      )
    ) : (
      <OttoMessageBox
        headingText={uiStrings.welcomeToOtto}
        paragraphs={[
          uiStrings.noSitesSetupCurrently,
          uiStrings.noSitesSetupCurrentlyDisclaimer,
        ]}
      />
    )
  ) : (
    overviewSpinner(uiStrings.fetchingListOfOttoSites)
  );
}

export default Overview;
