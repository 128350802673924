import { LineChartProps } from '@amzn/awsui-components-react/polaris/line-chart';
import { MixedLineBarChartProps } from '@amzn/awsui-components-react/polaris/mixed-line-bar-chart';

import { uiStrings } from '@/library/constants/uiStrings';
import { iUtilizationData } from '@/library/storeSlices/overviewData/overviewDataTypes';
import { formatDate, getUTCDateByDateString } from '@/library/utils/dateUtils';
import { concatPercent } from '@/library/utils/stringUtils';

/*
 * Labour utilization graph is received in form of array. It is formatted to array of {x: {x-data}, y: {y-data} }
 */
function utilizationDataConversion(
  graphData: iUtilizationData[],
  utilization: string,
): ReadonlyArray<MixedLineBarChartProps.Datum<string>> {
  let yAxisData: number;
  return graphData.map(utilizationData => {
    switch (utilization) {
      case 'dtw':
        yAxisData = utilizationData.downTimeWindow ?? -1;
        break;
      case 'ort':
        yAxisData = utilizationData.operationalRunTime ?? -1;
        break;
      default:
        yAxisData = utilizationData.overallUtilization ?? -1;
        break;
    }
    return {
      x: formatDate(
        getUTCDateByDateString(utilizationData.date),
        '{ddd}, {MMM} {DD}',
      ),
      y: yAxisData,
    };
  });
}

export const getSeriesData = (
  graphData,
): { data: LineChartProps<string>['series']; maxY: number } => {
  const dtwUtilization = utilizationDataConversion(graphData, 'dtw');
  const ortUtilization = utilizationDataConversion(graphData, 'ort');
  const overallUtilization = utilizationDataConversion(graphData, 'overall');
  const maxY =
    Math.max(
      Math.max(...dtwUtilization.map(o => o.y)),
      Math.max(...ortUtilization.map(o => o.y)),
      Math.max(...overallUtilization.map(o => o.y)),
    ) + 5;
  const valueFormatter = (e: number): string =>
    e < 0 ? uiStrings.noneAvailable : concatPercent(e);

  return !graphData?.length
    ? { data: [], maxY: 0 }
    : {
        data: [
          {
            title: uiStrings.comparisonGraphPopoverDTWTitle,
            type: 'line',
            data: dtwUtilization,
            valueFormatter,
            color: '#9FDEF1',
          },
          {
            title: uiStrings.comparisonGraphPopoverORTTitle,
            type: 'line',
            data: ortUtilization,
            valueFormatter,
            color: '#212121',
          },
          {
            title: uiStrings.comparisonGraphPopoverOverallTitle,
            type: 'line',
            data: overallUtilization,
            valueFormatter,
            color: '#B9E52F',
          },
        ],
        maxY,
      };
};
