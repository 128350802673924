import React, { ReactElement } from 'react';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Badge from '@/library/components/atoms/Badge';
import Tooltip from '@/library/components/molecules/Tooltip';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';

interface iWorkOrderErrorBadgeProps {
  woData: iFormattedRecommendationsData;
}

export function WorkOrderErrorBadge(
  props: iWorkOrderErrorBadgeProps,
): ReactElement {
  const { woData } = props;
  const { workOrdersTableErrors } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const { count, details } = workOrdersTableErrors[woData.workOrderID];
  return count > 0 ? (
    <Tooltip
      triggerType="custom"
      content={
        <ul>
          {details.map(error => (
            <li key={error.columnId}>{error.message}</li>
          ))}
        </ul>
      }
      position="top"
    >
      <div className="wo-error-badge">
        <Badge color="red">&nbsp;{count}&nbsp;</Badge>
      </div>
    </Tooltip>
  ) : (
    <></>
  );
}
