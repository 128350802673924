import React, { ReactElement } from 'react';

import Box from '@amzn/awsui-components-react/polaris/box';

import Button from '@/library/components/atoms/Button';
import Card from '@/library/components/atoms/Card';
import Tooltip from '@/library/components/molecules/Tooltip';
import { uiStrings } from '@/library/constants/uiStrings';

export interface iDaysOfBacklogCardProps {
  daysOfBacklog: number | string;
}

export function DaysOfBacklogCard(
  props: iDaysOfBacklogCardProps,
): ReactElement {
  const { daysOfBacklog } = props;
  return (
    <Card>
      <Box>
        {uiStrings.daysOfBacklog}
        <Tooltip
          dismissButton={false}
          position="top"
          size="small"
          triggerType="custom"
          content={uiStrings.backlogDaysDescription}
        >
          <Button
            iconName="status-info"
            variant="inline-icon"
          />
        </Tooltip>
      </Box>
      <Box
        fontSize="display-l"
        fontWeight="bold"
      >
        {daysOfBacklog}
      </Box>
    </Card>
  );
}
