import React, { ReactElement } from 'react';

import Icon from '@/library/components/atoms/Icon';
import Spinner from '@/library/components/atoms/Spinner';

import './stepProgressStyles.scss';

interface iStepProgressProps {
  loading?: boolean;
  waiting?: boolean;
  success?: boolean;
  failed?: boolean;
  stepLabel: string;
}

export function StepProgress(props: iStepProgressProps): ReactElement {
  const { loading, waiting, failed, stepLabel, success } = props;
  return (
    <span
      className={`step-progress ${
        !waiting && !loading
          ? failed
            ? 'step-failed'
            : success
            ? 'step-completed'
            : ''
          : ''
      }`}
    >
      {waiting ? (
        <Icon name="status-pending" />
      ) : loading ? (
        <Spinner />
      ) : success ? (
        <Icon name="status-positive" />
      ) : failed ? (
        <Icon name="status-negative" />
      ) : (
        ''
      )}

      <span className="step-text">{stepLabel}</span>
    </span>
  );
}
