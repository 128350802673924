import { overviewServiceAPI } from '@/configs/apiServiceConfig/apiServiceConfig';
import { apiNames, apiRoutes } from '@/library/constants/apiConstants';
import { appConstants } from '@/library/constants/appConstants';
import {
  formatDate,
  getDateUsingTimeZoneOffSet,
  getUTCDate,
} from '@/library/utils/dateUtils';
import { updateEndpointWithPathParams } from '@/library/utils/urlUtils';

export const getForecastedData = (siteDetails, store): Promise<any> => {
  const siteCurrentDate = getDateUsingTimeZoneOffSet(
    getUTCDate(),
    siteDetails.utcOffset ?? 0,
  );
  const {
    ottoAppConfig: { noOfCardsOnOverviewPage },
  } = store.getState().masterDataReducer;

  const queryParams = {
    numOfDays: noOfCardsOnOverviewPage,
    startDate: formatDate(siteCurrentDate, appConstants.dateFormat),
  };
  return overviewServiceAPI
    .get(
      {
        route: updateEndpointWithPathParams(apiRoutes.overviewData, {
          site: siteDetails.siteId,
        }),
        name: apiNames.overviewData,
      },
      queryParams,
    )
    .then(response => ({
      siteData: response,
      siteCurrentDate,
    }));
};
