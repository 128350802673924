import React, { ReactElement, useEffect } from 'react';

import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Table from '@/library/components/atoms/Table';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { updateWOTablePreferences } from '@/library/storeSlices/masterData/masterDataActions';
import { updateSelectedWOs } from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import TableColumnHeader from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableColumnHeader/TableColumnHeader';
import { recordSWOEvent } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

import TableFilters from '../TableFilters/TableFilters';
import TableHeader from '../TableHeader/TableHeader';
import {
  initialTableContentPreference,
  tableColumnConfig,
  tooltipHeader,
} from './tableColumnConfig';

import './scheduleWorkOrderTableStyles.scss';

function ScheduleWorkOrderTable(): ReactElement {
  const dispatch = useAppDispatch();

  const {
    woRecommendationsFilteredTableDataWithUserEdits,
    isEditSessionActive,
    selectedWOs,
    workOrdersTableErrors,
    isFetchingWORecommendations,
  } = useAppSelector(state => state.scheduleWODataReducer);

  const { woTablePreferences } = useAppSelector(
    state => state.masterDataReducer,
  );

  useEffect(() => {
    resetTablePreferenceIfDefaultPreferenceChanged();
  }, []);

  const resetTablePreferences = (): void => {
    dispatch(
      updateWOTablePreferences({
        pageSize: 10,
        contentDisplay: initialTableContentPreference,
      }),
    );
  };

  const resetTablePreferenceIfDefaultPreferenceChanged = (): void => {
    const { contentDisplay } = woTablePreferences;
    if (initialTableContentPreference.length !== contentDisplay.length) {
      resetTablePreferences();
    } else {
      const currentTablePreferenceObject = {};
      contentDisplay.forEach(currentPreference => {
        currentTablePreferenceObject[currentPreference.id] = currentPreference;
      });

      const isInitialPreferenceUpdated = initialTableContentPreference.some(
        initialPreference =>
          !currentTablePreferenceObject[initialPreference.id] ||
          currentTablePreferenceObject[initialPreference.id].label !==
            initialPreference.label ||
          currentTablePreferenceObject[initialPreference.id].alwaysVisible !==
            initialPreference.alwaysVisible,
      );

      if (isInitialPreferenceUpdated) {
        resetTablePreferences();
      }
    }
  };

  const onSelectWOs = (e): void => {
    dispatch(updateSelectedWOs(e.detail.selectedItems));
  };

  return (
    <div className="schedule-wo-table-container">
      <Table
        keepSelection
        selectionType={'multi'}
        onSelectionChange={onSelectWOs}
        selectedItems={selectedWOs}
        isItemDisabled={() => !isEditSessionActive}
        columnDefinitions={[
          {
            ...tableColumnConfig.errors,
            header: (
              <TableColumnHeader
                headerText={tooltipHeader(
                  uiStrings.error,
                  uiStrings.errorHeaderDescription,
                )}
                sortingComparator={(a, b) =>
                  workOrdersTableErrors[a.workOrderID]?.count ===
                  workOrdersTableErrors[b.workOrderID]?.count
                    ? 0
                    : workOrdersTableErrors[a.workOrderID]?.count >
                      workOrdersTableErrors[b.workOrderID]?.count
                    ? 1
                    : -1
                }
                columnId="errors"
              />
            ),
          },
          ...woTablePreferences.contentDisplay
            .filter(column => column.visible && tableColumnConfig[column.id])
            .map(column => tableColumnConfig[column.id]),
        ]}
        items={woRecommendationsFilteredTableDataWithUserEdits}
        trackBy="workOrderID"
        header={<TableHeader />}
        filter={<TableFilters />}
        loading={isFetchingWORecommendations}
        loadingText={uiStrings.fetchingWORecommendations}
        preferences={
          <CollectionPreferences
            title={uiStrings.tablePreferences}
            confirmLabel={uiStrings.apply}
            cancelLabel={uiStrings.cancel}
            onConfirm={({ detail }) => {
              recordSWOEvent(metricNames.swo_applyTablePreferences);
              dispatch(updateWOTablePreferences(detail));
            }}
            preferences={woTablePreferences}
            pageSizePreference={{
              title: uiStrings.numberOfWOsPerPage,
              options: [
                { value: 10, label: '10 ' + uiStrings.workOrders },
                { value: 20, label: '20 ' + uiStrings.workOrders },
                { value: 50, label: '50 ' + uiStrings.workOrders },
              ],
            }}
            contentDisplayPreference={{
              title: uiStrings.columnDisplayPreferences,
              options: initialTableContentPreference,
            }}
          />
        }
        pageSize={woTablePreferences.pageSize}
      />
    </div>
  );
}

export default ScheduleWorkOrderTable;
