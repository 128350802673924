import React, { ReactElement, useCallback } from 'react';

import PolarisCalendar, {
  CalendarProps,
} from '@amzn/awsui-components-react/polaris/calendar';

import { appConstants } from '@/library/constants/appConstants';
import {
  formatDate,
  getDaysDifferenceBetweenDates,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';

interface iCalendarProps extends CalendarProps {
  minDate?: Date;
  maxDate?: Date;
}

export function Calendar(props: iCalendarProps): ReactElement {
  const { isDateEnabled, minDate, maxDate } = props;

  const isDateEnabledForGivenDateRange = useCallback(
    date => {
      const formattedDate = getUTCDateByDateString(
        formatDate(date, appConstants.dateFormat),
      );
      if (minDate && maxDate) {
        const differenceBetweenDateAndMinDate = getDaysDifferenceBetweenDates(
          minDate,
          formattedDate,
        );
        const differenceBetweenDateAndMaxDate = getDaysDifferenceBetweenDates(
          formattedDate,
          maxDate,
        );
        return (
          differenceBetweenDateAndMinDate >= 0 &&
          differenceBetweenDateAndMaxDate >= 0
        );
      } else if (minDate) {
        const differenceBetweenDateAndMinDate = getDaysDifferenceBetweenDates(
          minDate,
          formattedDate,
        );
        return differenceBetweenDateAndMinDate >= 0;
      } else if (maxDate) {
        const differenceBetweenDateAndMaxDate = getDaysDifferenceBetweenDates(
          formattedDate,
          maxDate,
        );
        return differenceBetweenDateAndMaxDate >= 0;
      }
      return true;
    },
    [minDate, maxDate],
  );

  return (
    <PolarisCalendar
      {...props}
      isDateEnabled={isDateEnabled ?? isDateEnabledForGivenDateRange}
    />
  );
}
