import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from '@/library/constants/actionTypes';
import { appConstants } from '@/library/constants/appConstants';
import { fetchForecastedData } from '@/library/storeSlices/overviewData/overviewDataActions';
import {
  iDaysOfBacklog,
  iUtilizationData,
} from '@/library/storeSlices/overviewData/overviewDataTypes';
import {
  formatDate,
  getDatesInRange,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { sortByProperty } from '@/library/utils/objectUtils';

type iForecastedData = Record<
  string,
  {
    utilizationDetails: iUtilizationData[];
    daysOfBacklog: iDaysOfBacklog[];
  }
>;

export interface iOverviewData {
  siteData: iForecastedData;
}

const initialState: iOverviewData = {
  siteData: {},
};

const overviewDataSlice = createSlice({
  name: sliceNames.overviewData,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchForecastedData.fulfilled, (state, action) => {
      const labourUtilizationData = action.payload.siteData;
      const siteId: string = action.payload.siteId as string;

      const utilizationDetails: iUtilizationData[] = sortByProperty(
        labourUtilizationData?.utilizationDetails,
        'date',
        'ISO_8601',
      ).map(data => {
        const updatedRecord = { ...data };

        updatedRecord.hoursToAdjust = parseFloat(
          (data.totalAvailableHours || data.totalRemovableHours).toFixed(2),
        );

        const getUtilisationPercent = (utilisation): number =>
          utilisation < 0
            ? utilisation
            : parseFloat((utilisation * 100).toFixed(2));

        updatedRecord.downTimeWindow = getUtilisationPercent(
          updatedRecord.downTimeWindow,
        );
        updatedRecord.operationalRunTime = getUtilisationPercent(
          updatedRecord.operationalRunTime,
        );
        updatedRecord.overallUtilization = getUtilisationPercent(
          updatedRecord.overallUtilization,
        );
        return updatedRecord;
      });

      const utilisationDetailsForAllDates: iUtilizationData[] = [];
      if (utilizationDetails.length) {
        const siteFirstDate: Date = action.payload.siteCurrentDate;
        const siteLastDate: Date = getUTCDateByDateString(
          utilizationDetails[utilizationDetails.length - 1].date,
        );
        const listOfDates = getDatesInRange(siteFirstDate, siteLastDate).map(
          date => formatDate(date, appConstants.dateFormat),
        );

        listOfDates.forEach(date => {
          const utilizationDetailsForDate = utilizationDetails.find(
            data => data.date === date,
          );
          const defaultUtilization: iUtilizationData = {
            date,
            overallUtilization: -1,
            downTimeWindow: -1,
            operationalRunTime: -1,
            hoursToAdjust: 0,
            workOrdersRecommendationFilePresignedUrl: '',
            error: false,
          };
          utilisationDetailsForAllDates.push(
            utilizationDetailsForDate ?? defaultUtilization,
          );
        });
      }

      state.siteData[siteId] = {
        utilizationDetails: utilisationDetailsForAllDates,
        daysOfBacklog: labourUtilizationData?.daysOfBacklog,
      };
    });
  },
});

export default overviewDataSlice.reducer;
