import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getUserAuthorisedSites } from '@/library/apiServices/apmServices';
import {
  getOttoSites,
  validateAPMSessionIdAndGetUserDetails,
} from '@/library/apiServices/authServices';
import { actionTypes } from '@/library/constants/actionTypes';

export const validateAPMSessionIdAndFetchUserDetails = createAsyncThunk(
  actionTypes.validateAPMSessionIdAndFetchUserDetails,
  (data, thunkAPI) =>
    validateAPMSessionIdAndGetUserDetails().catch(err =>
      thunkAPI.rejectWithValue(err.response),
    ),
);

export const fetchUserAuthorisedSites = createAsyncThunk(
  actionTypes.fetchUserAuthorisedSites,
  (userId: string, thunkAPI) =>
    getUserAuthorisedSites(userId).catch(err =>
      thunkAPI.rejectWithValue(err.response),
    ),
);

export const fetchOttoSites = createAsyncThunk(
  actionTypes.fetchOttoSites,
  (args, thunkAPI) =>
    getOttoSites().catch(err => thunkAPI.rejectWithValue(err.response)),
);

export const updateSelectedSite = createAction(
  actionTypes.updatedSelectedSite,
  payload => ({
    payload,
  }),
);

export const updateOttoAppConfig = createAction(
  actionTypes.updateOttoAppConfig,
  payload => ({
    payload,
  }),
);

export const updateWOTablePreferences = createAction(
  actionTypes.updateWOTablePreferences,
  payload => ({
    payload,
  }),
);
