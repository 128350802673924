import React, { ReactElement, useMemo, useState } from 'react';

import Card from '@/library/components/atoms/Card/';
import Icon from '@/library/components/atoms/Icon';
import StatusIndicator from '@/library/components/atoms/StatusIndicator';
import OverviewRecommendedChangesTableModal from '@/library/components/organisms/OverviewRecommendedChangesTableModal';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { iUtilizationData } from '@/library/storeSlices/overviewData/overviewDataTypes';
import {
  getDateComponents,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { recordClickEvent } from '@/library/utils/metricsUtils';
import { roundOffToDecimalPlaces } from '@/library/utils/numberUtils';
import { concatPercent } from '@/library/utils/stringUtils';
import {
  isLaborOverUtilized,
  isLaborUnderUtilized,
  overUtilisedThreshold,
  underUtilisedThreshold,
} from '@/pages/Overview/overviewUtils';

import './overviewCardStyles.scss';

export function OverviewCard(props: iUtilizationData): ReactElement {
  const {
    date,
    downTimeWindow,
    operationalRunTime,
    overallUtilization,
    hoursToAdjust,
    workOrdersRecommendationFilePresignedUrl,
    error,
    shiftUtilizationData,
  } = props;

  const [
    isRecommendedChangesModalVisible,
    setIsRecommendedChangesModalVisible,
  ] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const { MMMM, DD } = useMemo(
    () => getDateComponents(getUTCDateByDateString(date)),
    [date],
  );
  const isOverUtilized = isLaborOverUtilized(overallUtilization);
  const isUnderUtilized = isLaborUnderUtilized(overallUtilization);

  const isInvalidUtilisationData =
    (overallUtilization == null || overallUtilization < 0) &&
    (downTimeWindow == null || downTimeWindow < 0) &&
    (operationalRunTime == null || operationalRunTime < 0);

  const displayUtilisationPercent = (percent?: number): string =>
    percent == null || percent < 0
      ? uiStrings.noneAvailable
      : concatPercent(percent);

  const onClickViewRecommendedChanges = (): void => {
    recordClickEvent(metricNames.overview_viewRecommendedChanges);
    setIsRecommendedChangesModalVisible(true);
  };

  const handleExpand = (): void => {
    if (!isExpanded) {
      recordClickEvent(metricNames.overview_viewShiftUtilization);
    }
    setIsExpanded(!isExpanded);
  };

  let shiftCountWithUnderUtilisation = 0;
  let shiftCountWithOverUtilisation = 0;
  let shiftCountWithAcceptableUtilisation = 0;

  (shiftUtilizationData ?? []).forEach(utilizationData => {
    const utilization = roundOffToDecimalPlaces(
      utilizationData.utilization * 100,
      2,
    );
    if (isLaborUnderUtilized(utilization)) {
      shiftCountWithUnderUtilisation++;
    } else if (isLaborOverUtilized(utilization)) {
      shiftCountWithOverUtilisation++;
    } else {
      shiftCountWithAcceptableUtilisation++;
    }
  });

  return (
    <div className="overview-card-container">
      <div>
        <OverviewRecommendedChangesTableModal
          visible={isRecommendedChangesModalVisible}
          onDismiss={() => setIsRecommendedChangesModalVisible(false)}
          overviewData={props}
        />
        <div
          className={`card-meta-data ${
            isOverUtilized
              ? 'over-utilized'
              : isUnderUtilized
              ? 'under-utilized'
              : error || isInvalidUtilisationData
              ? 'invalid-utilized'
              : ''
          }`}
        >
          {isOverUtilized || isUnderUtilized ? (
            <>
              <span className="utilization-flag">
                <span
                  className={
                    isOverUtilized ? 'arrow-icon-up' : 'arrow-icon-down'
                  }
                >
                  <Icon name="arrow-left" />
                </span>
                <span>
                  {isOverUtilized
                    ? overUtilisedThreshold
                    : underUtilisedThreshold}
                  % {uiStrings.utilization}
                </span>
              </span>
              {workOrdersRecommendationFilePresignedUrl && (
                <span
                  onClick={onClickViewRecommendedChanges}
                  className="recommended-changes-button"
                >
                  {uiStrings.viewRecommendedChanges}
                </span>
              )}
            </>
          ) : (
            !(error || isInvalidUtilisationData) && (
              <>
                <span className="utilization-flag">
                  <span className="check-icon">
                    <Icon name="check" />
                  </span>
                  <span>{uiStrings.acceptableUtilizationRange}</span>
                </span>
                {workOrdersRecommendationFilePresignedUrl && (
                  <span
                    onClick={onClickViewRecommendedChanges}
                    className="recommended-changes-button"
                  >
                    {uiStrings.viewRecommendedChanges}
                  </span>
                )}
              </>
            )
          )}
        </div>
        <Card
          className="card-container"
          disableContentPaddings
        >
          <div className="card-calender-container">
            <div className="utilization-month-container">
              {MMMM.toUpperCase()}
            </div>
            <div className="utilization-date-container">{DD}</div>
          </div>
          <div className="card-partition-container" />
          <div className="card-utilization-content">
            {error || isInvalidUtilisationData ? (
              <div className="card-error-container">
                <StatusIndicator
                  type="error"
                  colorOverride="grey"
                >
                  {!error
                    ? uiStrings.siteNotProperlyConfigured
                    : uiStrings.unableToLoadLaborUtilizationData}
                </StatusIndicator>
              </div>
            ) : (
              <>
                <div>
                  <strong>
                    {uiStrings.labourUtilizationOverall} :{' '}
                    {displayUtilisationPercent(overallUtilization)}
                    {isOverUtilized || isUnderUtilized
                      ? ' (' +
                        hoursToAdjust +
                        ' ' +
                        (isOverUtilized
                          ? uiStrings.hoursOver
                          : uiStrings.hoursAvailable) +
                        ')'
                      : ''}
                  </strong>
                </div>
                <div>
                  {uiStrings.dtwUtilization}:{' '}
                  {displayUtilisationPercent(downTimeWindow)}
                </div>
                <div>
                  {uiStrings.ortUtilization}:{' '}
                  {displayUtilisationPercent(operationalRunTime)}
                </div>
                <div className="shift-utilization-badge-container">
                  {!shiftCountWithUnderUtilisation &&
                    !shiftCountWithOverUtilisation &&
                    !!shiftCountWithAcceptableUtilisation && (
                      <span className="shift-utilization-badge">
                        <Icon name="check" /> {uiStrings.shiftUtilization}
                      </span>
                    )}
                  {!!shiftCountWithUnderUtilisation && (
                    <span className="shift-utilization-badge under-utilised-shift-badge">
                      {`${shiftCountWithUnderUtilisation} ${
                        shiftCountWithUnderUtilisation > 1
                          ? uiStrings.shifts
                          : uiStrings.shift
                      } < ${underUtilisedThreshold}%`}
                    </span>
                  )}
                  {!!shiftCountWithOverUtilisation && (
                    <span className="shift-utilization-badge over-utilised-shift-badge">
                      {`${shiftCountWithOverUtilisation} ${
                        shiftCountWithOverUtilisation > 1
                          ? uiStrings.shifts
                          : uiStrings.shift
                      } > ${overUtilisedThreshold}%`}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </Card>
        <Card
          disableContentPaddings
          className={`shift-utilization-container ${
            isExpanded ? 'expand' : ''
          }`}
        >
          <div className="shift-utilization-content">
            {(shiftUtilizationData ?? []).map(utilizationData => {
              const utilization = roundOffToDecimalPlaces(
                utilizationData.utilization * 100,
                2,
              );
              return (
                <div
                  key={utilizationData.id}
                  className={`shift-utilization ${
                    isLaborUnderUtilized(utilization)
                      ? 'shift-under-utilized'
                      : isLaborOverUtilized(utilization)
                      ? 'shift-over-utilized'
                      : ''
                  }`}
                >
                  {utilizationData.id}: {utilization}%
                </div>
              );
            })}
          </div>
        </Card>
      </div>
      <div className="expand-button-container">
        {!!shiftUtilizationData?.length && (
          <div
            className="expand-button"
            onClick={handleExpand}
          >
            <span>{uiStrings.viewShiftUtilization}</span>
            <div
              className={`expand-button-icon ${isExpanded ? 'up-arrow' : ''}`}
            >
              <Icon name="angle-down" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
