import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import { appRoutes } from '@/library/constants/appRoutes';
import { getSessionIdAndUserIdFromSearchParams } from '@/library/utils/urlUtils';

const { sessionId } = getSessionIdAndUserIdFromSearchParams();

function PublicAppLayout(): ReactElement {
  const userDetails = useAppSelector(
    state => state.masterDataReducer.userDetails,
  );

  const isAuthenticated =
    userDetails?.userId && (!sessionId || sessionId === userDetails.sessionId);

  return !isAuthenticated ? (
    <div className="public-app-layout-container">
      <Outlet />
    </div>
  ) : (
    <Navigate
      to={appRoutes.overview}
      replace
    />
  );
}

export default PublicAppLayout;
