import React, { ReactElement } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import PolarisTable, {
  TableProps,
} from '@amzn/awsui-components-react/polaris/table';

import HeaderText from '@/library/components/atoms/HeaderText';
import Pagination from '@/library/components/atoms/Pagination';
import { uiStrings } from '@/library/constants/uiStrings';

import './tableStyles.scss';

export interface iTableProps extends TableProps {
  tableHeading?: string | ReactElement;
  keepSelection?: boolean;
  pageSize?: number;
  items: any[];
}

export function Table(props: iTableProps): ReactElement {
  const {
    tableHeading,
    keepSelection = true,
    pageSize = 10,
    items,
    visibleColumns,
    columnDefinitions,
    selectedItems = [],
  } = props;

  const {
    items: tableData,
    collectionProps,
    paginationProps,
  } = useCollection(items, {
    filtering: {},
    pagination: { pageSize },
    sorting: {},
    selection: {
      keepSelection,
    },
  });

  return (
    <PolarisTable
      {...collectionProps}
      stripedRows
      stickyHeader
      resizableColumns
      empty={
        <div>
          <b>{uiStrings.noDataFound}</b>
        </div>
      }
      header={
        <HeaderText
          counter={
            selectedItems.length > 0
              ? '(' + selectedItems.length + '/' + items.length + ')'
              : '(' + items.length + ')'
          }
        >
          {tableHeading}
        </HeaderText>
      }
      pagination={<Pagination {...paginationProps} />}
      {...props}
      items={tableData}
      visibleColumns={
        visibleColumns ?? columnDefinitions.map(column => column.id ?? '')
      }
    />
  );
}
