import React, { ReactElement, useEffect, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Banner from '@/library/components/atoms/Banner';
import Button from '@/library/components/atoms/Button';
import ConfirmationModal from '@/library/components/molecules/ConfirmationModal';
import OttoMessageBox from '@/library/components/molecules/OttoMessageBox';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  stopWOEditSession,
  updateScheduleWOMessageBanner,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { initialiseWorkOrdersData } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

import ScheduleWorkOrderTable from './scheduleWorkOrdersFrames/ScheduleWorkOrderTable/ScheduleWorkOrderTable';

import './scheduleWorkOrdersStyles.scss';

function ScheduleWorkOrders(): ReactElement {
  const dispatch = useAppDispatch();
  const [
    isRefreshScheduleConfirmationModalVisible,
    setIsRefreshScheduleConfirmationModalVisible,
  ] = useState(false);

  const { sectionizedListOfSites, activeSite, ottoAppConfig } = useAppSelector(
    state => state.masterDataReducer,
  );

  const {
    scheduleWOMessageBannerData,
    isEditSessionActive,
    recommendationGenerationStatus,
  } = useAppSelector(state => state.scheduleWODataReducer);

  const isSiteEnabledForAdminTab =
    ottoAppConfig.scheduleWorkOrdersTabEnabledSites.includes(
      activeSite?.siteId ?? '',
    );

  useEffect(() => {
    if (
      sectionizedListOfSites?.ottoEnabledUserAuthorizedSites.length &&
      activeSite?.siteId &&
      isSiteEnabledForAdminTab &&
      !isEditSessionActive
    ) {
      initScheduleWorkOrdersTab();
    }
  }, [activeSite]);

  useEffect(() => {
    if (recommendationGenerationStatus.isNewRunAvailable) {
      dispatch(
        updateScheduleWOMessageBanner({
          type: 'info',
          content: (
            <div className="new-run-banner-container">
              <div>
                <p className="new-run-heading">{uiStrings.newRunAvailable}</p>
                <p>{uiStrings.newRecommendationsAvailableForSite}</p>
              </div>
              <Button
                metricId={
                  metricNames.swo_newRunAvailableRefreshScheduleConfirmation
                }
                onClick={() => {
                  setIsRefreshScheduleConfirmationModalVisible(true);
                }}
              >
                {uiStrings.refreshSchedule}
              </Button>
            </div>
          ),
          id: 'newRunAvailable',
          onDismiss: () =>
            dispatch(updateScheduleWOMessageBanner('newRunAvailable')),
          dismissible: true,
        }),
      );
    } else if (recommendationGenerationStatus.isNewRunInProgress) {
      dispatch(
        updateScheduleWOMessageBanner({
          type: 'info',
          content: uiStrings.dailyRunInProgress,
          dismissible: true,
          loading: true,
          id: 'newRunInProgress',
          onDismiss: () =>
            dispatch(updateScheduleWOMessageBanner('newRunInProgress')),
        }),
      );
    }
  }, [recommendationGenerationStatus]);

  const initScheduleWorkOrdersTab = (): void => {
    initialiseWorkOrdersData(dispatch);
  };

  const hideRefreshScheduleConfirmationModal = (): void => {
    setIsRefreshScheduleConfirmationModalVisible(false);
  };

  const onConfirmRefreshSchedule = (): void => {
    dispatch(
      stopWOEditSession(
        metricNames.swo_newRunAvailableRefreshScheduleConfirmation,
      ),
    );
    hideRefreshScheduleConfirmationModal();
    initScheduleWorkOrdersTab();
  };

  return isSiteEnabledForAdminTab ? (
    <div id="schedule-work-order-tab-container">
      <ConfirmationModal
        visible={isRefreshScheduleConfirmationModalVisible}
        onDismiss={hideRefreshScheduleConfirmationModal}
        onConfirm={onConfirmRefreshSchedule}
        header={uiStrings.confirmScheduleRefresh}
        confirmButtonLabel={uiStrings.confirmRefresh}
      >
        <p>{uiStrings.refreshScheduleConfirmationDescription}</p>
      </ConfirmationModal>
      <div className="schedule-wo-message-banner-container">
        <Banner items={scheduleWOMessageBannerData} />
      </div>
      <ScheduleWorkOrderTable />
    </div>
  ) : (
    <OttoMessageBox
      headingText={uiStrings.comingSoon}
      paragraphs={[uiStrings.featureNotAvailableForSite]}
    />
  );
}

export default ScheduleWorkOrders;
