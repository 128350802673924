import React, { ReactElement } from 'react';

import PolarisBadge, {
  BadgeProps,
} from '@amzn/awsui-components-react/polaris/badge';

export interface iBadgeProps extends BadgeProps {}

export function Badge(props: iBadgeProps): ReactElement {
  return <PolarisBadge {...props} />;
}
