import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { metricTypes } from '@/library/constants/metricConstants';

export const recordClickEvent = (buttonId): void => {
  recordEvent(metricTypes.click, { buttonId });
};

interface iRecordLatencyEvent {
  setStartPoint: () => void;
  setEndPoint: () => void;
}

export const recordLatencyEvent = (latencyMetricName): iRecordLatencyEvent => {
  let startTimestamp = 0;
  return {
    setStartPoint: () => {
      startTimestamp = Date.now();
    },
    setEndPoint: () => {
      const currentTimestamp = Date.now();
      recordEvent(metricTypes.latency, {
        latencyMetricName,
        latency: currentTimestamp - startTimestamp,
      });
      recordEvent(latencyMetricName, {
        latency: currentTimestamp - startTimestamp,
        value: currentTimestamp - startTimestamp,
      });
    },
  };
};
