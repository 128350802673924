import React, { ReactElement } from 'react';

import Flashbar, {
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris/flashbar';

export interface iBannerProps extends FlashbarProps {}

export interface iMessageDefinition extends FlashbarProps.MessageDefinition {}

export function Banner(props: iBannerProps): ReactElement {
  return <Flashbar {...props} />;
}
