import envConfig from '@/configs/envConfig';

export const appStages = {
  development: 'development',
  unitTest: 'development',
  beta: 'beta',
  gamma: 'gamma',
  gammaDevelopmentPreview: 'gammaDevelopmentPreview',
  prod: 'prod',
};

export const stageURL = {
  development: 'localhost:8080',
  unitTest: 'localhost:3000',
  beta: 'beta.otto.rme.amazon.dev',
  gamma: 'gamma.otto.rme.amazon.dev',
  gammaDevelopmentPreview: 'localhost:4173',
  prod: 'otto.rme.amazon.dev',
};

export const currentStageHostURL = location.host;
export const currentStageOriginURL = location.origin;

export const currentStage = (): string =>
  appStages[
    Object.keys(stageURL).find(key => stageURL[key] === currentStageHostURL) ??
      ''
  ] ?? '';

export const verifyCurrentStage = {
  isDevelopmentStage:
    currentStage() === appStages.development ||
    currentStage() === appStages.unitTest,
  isBetaStage: currentStage() === appStages.beta,
  isGammaStage:
    currentStage() === appStages.gamma ||
    currentStage() === appStages.gammaDevelopmentPreview,
  isProdStage: currentStage() === appStages.prod,
};

export const envData = envConfig[currentStage() + 'Config'];
