import React, { ReactElement, useMemo } from 'react';

import Select, {
  SelectProps,
} from '@amzn/awsui-components-react/polaris/select';

import './selectDropdownStyles.scss';

export interface iSelectDropdownProps extends SelectProps {
  buttonType?: 'inline';
  loading?: boolean;
  error?: boolean;
  labelKey?: string;
  valueKey?: string;
  options: any[];
}

export function SelectDropdown(props: iSelectDropdownProps): ReactElement {
  const {
    buttonType,
    options = [],
    loading,
    error,
    labelKey = 'label',
    valueKey = 'value',
  } = props;

  const formatOptions = useMemo((): Array<{ label: string; value: string }> => {
    if (labelKey !== 'label' || valueKey !== 'value') {
      const formattedOptions: Array<{ label: string; value: string }> = [];
      options.forEach(option => {
        formattedOptions.push({
          label: option[labelKey],
          value: option[valueKey],
        });
      });
      return formattedOptions;
    }
    return options;
  }, [options, labelKey, valueKey]);

  return (
    <Select
      data-buttontype={buttonType}
      expandToViewport
      virtualScroll={formatOptions.length >= 500}
      statusType={loading ? 'loading' : error ? 'error' : 'finished'}
      filteringType="auto"
      {...props}
      options={formatOptions}
    />
  );
}
