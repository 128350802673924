import React, { ReactElement, useCallback } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import SelectDropdown from '@/library/components/atoms/SelectDropdown';
import OverwriteReasonModal from '@/library/components/organisms/OverwriteReasonModal';
import { uiStrings } from '@/library/constants/uiStrings';
import { editWORecommendation } from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { InlineOverwriteTriggerButton } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/InlineOverwriteTriggerButton';

interface iSchedulingBlockOverwriteInputProps {
  woData: iFormattedRecommendationsData;
}

export function SchedulingBlockOverwriteInput(
  props: iSchedulingBlockOverwriteInputProps,
): ReactElement {
  const { woData } = props;

  const { ottoAppConfig } = useAppSelector(state => state.masterDataReducer);

  const dispatch = useAppDispatch();

  const updateWOData = (
    newValue,
    overwriteReasons,
    overwriteReasonComment,
  ): unknown =>
    dispatch(
      editWORecommendation({
        ...woData,
        schedulingBlock: newValue.value,
        schedulingBlockOverwriteReasons: overwriteReasons,
        schedulingBlockOverwriteReasonComment: overwriteReasonComment,
      }),
    );

  const triggerButton = useCallback(
    (toggleModal): ReactElement => (
      <InlineOverwriteTriggerButton value={woData.schedulingBlock}>
        <SelectDropdown
          buttonType="inline"
          selectedOption={{
            label: woData.schedulingBlock,
            value: woData.schedulingBlock,
          }}
          onChange={({ detail }) => {
            if (woData.schedulingBlockOverwriteReasons?.length) {
              updateWOData(
                { value: detail.selectedOption.value },
                woData.schedulingBlockOverwriteReasons,
                woData.schedulingBlockOverwriteReasonComment,
              );
            } else {
              toggleModal(detail.selectedOption.value);
            }
          }}
          options={[
            {
              label: uiStrings.unselect,
              value: '',
            },
            ...ottoAppConfig.schedulingBlocksList.map(block => ({
              label: block,
              value: block,
            })),
          ]}
        />
      </InlineOverwriteTriggerButton>
    ),
    [woData],
  );

  return (
    <OverwriteReasonModal
      onOverwriteValue={updateWOData}
      woId={woData.workOrderID}
      overwriteFieldLabel={uiStrings.woExecution}
      currentValue={woData.schedulingBlock}
      triggerButton={triggerButton}
      overwriteReasons={ottoAppConfig.schedulingBlockOverwriteReasons}
    />
  );
}
