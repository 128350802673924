import React, { ReactElement } from 'react';
import { useCSVDownloader } from 'react-papaparse';

import Button, { iButtonProps } from '@/library/components/atoms/Button';

interface iCsvDownloaderProps extends iButtonProps {
  data: unknown;
  filename?: string;
  children: ReactElement | ReactElement[] | string;
}

export function CsvDownloader(props: iCsvDownloaderProps): ReactElement {
  const { data, filename, disabled } = props;
  const { CSVDownloader, Type } = useCSVDownloader();

  return disabled ? (
    <Button
      variant="primary"
      iconName="download"
      {...props}
    />
  ) : (
    <CSVDownloader
      type={Type.Link}
      filename={filename ?? 'otto_download'}
      bom={true}
      data={data}
    >
      <Button
        variant="primary"
        iconName="download"
        {...props}
      />
    </CSVDownloader>
  );
}
