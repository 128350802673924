import React, { ReactElement } from 'react';

import PolarisLink, {
  LinkProps,
} from '@amzn/awsui-components-react/polaris/link';

export interface iLinkProps extends LinkProps {}

export function Link(props: iLinkProps): ReactElement {
  return <PolarisLink {...props} />;
}
