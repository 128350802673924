import React, { ReactElement, useState } from 'react';

import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button';
import OverviewCard from '@/library/components/organisms/OverviewCard';
import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { iUtilizationData } from '@/library/storeSlices/overviewData/overviewDataTypes';
import {
  formatDate,
  getDateUsingTimeZoneOffSet,
  getUTCDate,
} from '@/library/utils/dateUtils';

import './overviewCardsListStyles.scss';

export function OverviewCardsList(): ReactElement {
  const [showAllOverviewCards, setShowAllOverviewCards] = useState(false);

  const utilizationData = useAppSelector(
    state => state.overviewDataReducer.siteData,
  );

  const selectedSite = useAppSelector(
    state => state.masterDataReducer.activeSite,
  );

  const siteId = selectedSite?.siteId;

  const overviewData: iUtilizationData[] = siteId
    ? utilizationData[siteId]?.utilizationDetails
    : [];

  return (
    <>
      <ColumnLayout columns={2}>
        {overviewData?.length
          ? (showAllOverviewCards
              ? overviewData
              : overviewData.slice(0, 8)
            ).map(data => (
              <OverviewCard
                key={data.date}
                {...data}
              />
            ))
          : [...Array(8).keys()].map(index => (
              <OverviewCard
                key={index}
                date={formatDate(
                  getDateUsingTimeZoneOffSet(
                    getUTCDate(),
                    (selectedSite?.utcOffset ?? 0) + index * 24,
                  ),
                  appConstants.dateFormat,
                )}
                error
              />
            ))}
      </ColumnLayout>
      {!showAllOverviewCards && overviewData && overviewData.length > 8 && (
        <div className="load-more-button-container">
          <Button onClick={() => setShowAllOverviewCards(true)}>
            {uiStrings.loadMoreDates}
          </Button>
        </div>
      )}
    </>
  );
}
