import React, { ReactElement, useMemo } from 'react';

import MultiSelect, {
  MultiselectProps,
} from '@amzn/awsui-components-react/polaris/multiselect';

import './multiSelectDropdownStyles.scss';

export interface iMultiSelectDropdownProps extends MultiselectProps {
  buttonType?: 'inline';
  loading?: boolean;
  error?: boolean;
  labelKey?: string;
  valueKey?: string;
  options: any[];
}

export function MultiSelectDropdown(
  props: iMultiSelectDropdownProps,
): ReactElement {
  const {
    buttonType,
    options = [],
    loading,
    error,
    labelKey = 'label',
    valueKey = 'value',
  } = props;

  const formatOptions = useMemo((): Array<{
    label: string;
    value: string;
  }> => {
    if (labelKey !== 'label' || valueKey !== 'value') {
      const formattedOptions: Array<{ label: string; value: string }> = [];
      options.forEach(option => {
        formattedOptions.push({
          label: option[labelKey],
          value: option[valueKey],
        });
      });
      return formattedOptions;
    }
    return options;
  }, [options, labelKey, valueKey]);

  return (
    <MultiSelect
      data-buttontype={buttonType}
      expandToViewport
      virtualScroll={formatOptions.length >= 500}
      hideTokens
      statusType={loading ? 'loading' : error ? 'error' : 'finished'}
      filteringType="auto"
      {...props}
      options={formatOptions}
    />
  );
}
