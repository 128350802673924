import React, { ReactElement, useMemo, useState } from 'react';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Icon from '@/library/components/atoms/Icon';
import Select from '@/library/components/atoms/SelectDropdown';
import ConfirmationModal from '@/library/components/molecules/ConfirmationModal';
import {
  endSessionReasons,
  metricNames,
} from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { updateSelectedSite } from '@/library/storeSlices/masterData/masterDataActions';
import {
  resetScheduleWOTab,
  stopWOEditSession,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { siteState } from '@/library/utils/enums';
import ottoLogo from '@/resources/images/otto-logo-icon.png';

import './navBarStyles.scss';

interface iNavBarProps {
  authenticated?: boolean;
}

export function NavBar(props: iNavBarProps): ReactElement {
  const { authenticated } = props;
  const [
    endSessionConfirmationModalPayload,
    setIsEndSessionConfirmationModalVisible,
  ] = useState({ visible: false, data: {} });

  const dispatch = useAppDispatch();

  const {
    userAuthorisedSites,
    userAuthorisedSitesCount,
    activeSite,
    isFetchingOttoSites,
    isFetchingUserAuthorisedSites,
    sectionizedListOfSites,
    userDetails,
    ottoAppConfig,
  } = useAppSelector(state => state.masterDataReducer);

  const { isAnyEditMade, isEditSessionActive } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const handleChangeSite = (data): void => {
    if (isAnyEditMade || isEditSessionActive) {
      setIsEndSessionConfirmationModalVisible({ visible: true, data });
    } else {
      updateCurrentSite(data);
    }
  };

  const updateCurrentSite = (data): void => {
    const selectedSite = data.detail.selectedOption;

    recordEvent(metricNames.siteChange, {
      newSite: selectedSite.value,
    });

    dispatch(
      updateSelectedSite({
        siteId: selectedSite.value,
        state: siteState.ENABLED,
        utcOffset: sectionizedListOfSites?.ottoEnabledUserAuthorizedSites.find(
          site => site.siteId === selectedSite.value,
        ).utcOffset,
      }),
    );
    dispatch(resetScheduleWOTab());
  };

  const approvedAndEnabledSites = useMemo(
    () => [
      {
        label:
          uiStrings.ottoEnabledSites +
          ` (${
            sectionizedListOfSites?.ottoEnabledUserAuthorizedSites.length ?? 0
          })`,
        options: (
          sectionizedListOfSites?.ottoEnabledUserAuthorizedSites ?? []
        ).map(site => ({
          label: site.siteId,
          value: site.siteId,
        })),
      },
      {
        label:
          uiStrings.ottoDisabledSites +
          ` (${
            sectionizedListOfSites?.ottoDisabledUserAuthorizedSites.length ?? 0
          })`,
        disabled: true,
        options: (
          sectionizedListOfSites?.ottoDisabledUserAuthorizedSites ?? []
        ).map(site => ({
          label: site.siteId,
          value: site.siteId,
        })),
      },
    ],
    [sectionizedListOfSites],
  );

  const hideEndSessionConfirmationModal = (): void => {
    setIsEndSessionConfirmationModalVisible({ visible: false, data: {} });
  };

  const onConfirmEndSession = (): void => {
    dispatch(stopWOEditSession(endSessionReasons.userChangedSite));
    updateCurrentSite(endSessionConfirmationModalPayload.data);
    hideEndSessionConfirmationModal();
  };

  const onClickNeedHelp = (): void => {
    recordEvent(metricNames.needHelpLinkClick);
    window.open(ottoAppConfig.navBarNeedHelpLink, '_blank');
  };

  return (
    <div className="nav-bar-container">
      <ConfirmationModal
        visible={endSessionConfirmationModalPayload.visible}
        onDismiss={hideEndSessionConfirmationModal}
        onConfirm={onConfirmEndSession}
        header={uiStrings.selectDifferentSiteConfirmationTitle}
        confirmButtonLabel={uiStrings.selectDifferentSite}
      >
        <p>{uiStrings.siteChangeEndSessionConfirmationDescription}</p>
        <br />
        <p>{uiStrings.closeAndEndorseBeforeSwitchingSite}</p>
      </ConfirmationModal>
      <div className="nav-bar-section">
        <span className="otto-logo">
          <img
            src={ottoLogo}
            alt="Otto Icon"
            className="otto-logo"
          />
          otto
        </span>

        {authenticated && (
          <Select
            selectedOption={{
              label: activeSite?.siteId
                ? `${uiStrings.site}: ${activeSite?.siteId}`
                : uiStrings.selectSite,
              value: activeSite?.siteId || '',
            }}
            onChange={handleChangeSite}
            options={approvedAndEnabledSites}
            filteringType="auto"
            errorText={uiStrings.couldNotFetchAllSites}
            error={userAuthorisedSites?.length !== userAuthorisedSitesCount}
            loading={isFetchingOttoSites || isFetchingUserAuthorisedSites}
          />
        )}
      </div>
      {authenticated && (
        <div className="nav-bar-section">
          <div
            className="nav-bar-text need-help-link"
            onClick={onClickNeedHelp}
          >
            <span>{uiStrings.needHelp}</span>
            <Icon name="external" />
          </div>
          <div className="nav-bar-text">
            <Icon name="user-profile" />
            <span>{userDetails?.userId.split('@')[0].toLowerCase()}</span>
          </div>
        </div>
      )}
    </div>
  );
}
