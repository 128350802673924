import React, { ReactElement, useMemo } from 'react';

import Box, { BoxProps } from '@amzn/awsui-components-react/polaris/box';
import LineChart from '@amzn/awsui-components-react/polaris/line-chart';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Card from '@/library/components/atoms/Card';
import ComparisonChartHeader from '@/library/components/atoms/ComparisonChartHeader';
import { uiStrings } from '@/library/constants/uiStrings';
import { iUtilizationData } from '@/library/storeSlices/overviewData/overviewDataTypes';
import {
  concatPercent,
  formUiStringWithParameter,
} from '@/library/utils/stringUtils';

import { getSeriesData } from './overviewComparisonChartUtil';

import './overviewComparisonChartStyles.scss';

const emptyComponent = (): ReactElement => {
  const textProps: BoxProps = { textAlign: 'center', color: 'inherit' };
  return (
    <Box {...textProps}>{uiStrings.comparisonChartEmptyGraphDescription} </Box>
  );
};

export function OverviewComparisonChart(): ReactElement {
  const utilizationData = useAppSelector(
    state => state.overviewDataReducer.siteData,
  );
  const siteSelected = useAppSelector(
    state => state.masterDataReducer.activeSite?.siteId,
  );
  const {
    ottoAppConfig: { noOfCardsOnOverviewPage },
  } = useAppSelector(state => state.masterDataReducer);
  const graphData: iUtilizationData[] = siteSelected
    ? utilizationData[siteSelected]?.utilizationDetails?.slice(
        0,
        noOfCardsOnOverviewPage,
      ) ?? []
    : [];

  const graphFormattedData = useMemo(
    () => getSeriesData(graphData),
    [graphData],
  );

  return (
    <div className="comparison-chart">
      <Card>
        <>
          {!!graphData?.length && (
            <ComparisonChartHeader
              headerText={uiStrings.comparisonGraphHeader}
              description={formUiStringWithParameter(
                uiStrings.comparisonGraphSubHeader,
                { noOfCardsOnOverviewPage },
              )}
            />
          )}
          <LineChart
            series={graphFormattedData.data}
            i18nStrings={{
              yTickFormatter: (e: number) => concatPercent(e),
            }}
            errorText={uiStrings.comparisonChartErrorText}
            height={236}
            hideFilter
            loadingText={uiStrings.comparisonChartLoadingText}
            recoveryText={uiStrings.retry}
            xScaleType="categorical"
            xTitle={uiStrings.comparisonChartXAxis}
            yTitle={uiStrings.comparisonChartYAxis}
            empty={emptyComponent()}
            detailPopoverSize={'large'}
            yDomain={[0, graphFormattedData.maxY]}
          />
        </>
      </Card>
    </div>
  );
}
