import React, { ReactElement } from 'react';

import PolarisStatusIndicator, {
  StatusIndicatorProps,
} from '@amzn/awsui-components-react/polaris/status-indicator';

interface iStatusIndicatorProps extends StatusIndicatorProps {}

export function StatusIndicator(props: iStatusIndicatorProps): ReactElement {
  return <PolarisStatusIndicator {...props} />;
}
