import React, { ReactElement } from 'react';

import Card from '@/library/components/atoms/Card';
import ottoFullImage from '@/resources/images/otto-full-image.png';

import './ottoMessageBox.scss';

interface iOttoMessageBoxProps {
  headingText?: string;
  paragraphs?: Array<string | ReactElement>;
}

export function OttoMessageBox(props: iOttoMessageBoxProps): ReactElement {
  const { headingText, paragraphs } = props;

  return (
    <Card className="otto-message-box-container">
      <div className="otto-message-image-container">
        <img
          className="otto-message-image"
          src={ottoFullImage}
          alt="otto-logo"
        />
      </div>
      <div className="otto-message-text-container">
        <p className="otto-message-heading">{headingText}</p>
        {paragraphs?.map((paragraph, index) => (
          <span key={index}>{paragraph}</span>
        ))}
      </div>
    </Card>
  );
}
