import React, { ReactElement } from 'react';

import { ExpandableSection } from '@amzn/awsui-components-react';

import { uiStrings } from '@/library/constants/uiStrings';

export function TipsAndTricksContainer(): ReactElement {
  return (
    <ExpandableSection
      variant="container"
      headerText="Tips and tricks"
    >
      <ul>
        <li>
          <p>
            <b>{uiStrings.addingBulkData}:</b>{' '}
            {uiStrings.useSyntaxToAddBulkData}:
          </p>
          <i>{`Array{{<${uiStrings.commaSeparatedListHere}>}}`}</i>
          <p>
            {uiStrings.example}: <i>{'Array{{ABE2, MTN1, VGT2, OTH2}}'}</i>
          </p>
          <p>{uiStrings.duplicateValuesRemovedAutomatically}</p>
        </li>
        <li>
          <b>{uiStrings.reorderingValuesInField}:</b>{' '}
          {uiStrings.useArrowsToReposition}
        </li>
      </ul>
    </ExpandableSection>
  );
}
