export const prodConfig = {
  heimdallBaseURL: 'https://api.heimdall.na.rme.logistics.a2z.com',
  heimdallRegion: 'us-east-1',

  utilityServiceBaseURL:
    'https://utilityservice.na.api.prod.otto.rme.amazon.dev',
  utilityServiceRegion: 'us-east-1',
  ottoOverviewBaseUrl: 'https://overview.na.api.otto.rme.amazon.dev',
  ottoOverviewRegion: 'us-east-1',
  ottoRecommendationManagementServiceBaseUrl:
    'https://orms.us-east-1.api.otto.rme.amazon.dev',
  ottoRecommendationManagementServiceRegion: 'us-east-1',

  cognitoAuthRegion: 'us-east-1',
  cognitoAuthDomain: 'otto-apm.auth.us-east-1.amazoncognito.com',
  cognitoAuthIdentityPoolID: 'us-east-1:df9cdcec-eea2-4e8b-b81e-82391761773d',
  cognitoAuthUserPoolID: 'us-east-1_ZuINdfWqh',
  cognitoAuthUserPoolWebClientID: '76n8ceqqcm6khr0isj4vuscu5i',
  kmsGeneratorKeyID:
    'arn:aws:kms:us-east-1:098091307731:key/01eb2245-cc04-42cf-9fcd-abfd30d081e5',

  // Cloudwatch RUM
  cloudwatchGuestRoleArn:
    'arn:aws:iam::387428342818:role/RUM-Monitor-us-east-1-387428342818-5027779651961-Unauth',
  cloudwatchIdentityPoolId: 'us-east-1:bff228f5-f6a0-4ec2-af17-2a3146980b12',
  cloudwatchEndpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
  cloudwatchAppId: '71ce357b-ae41-47b7-8e16-d2e570584ca4',
  cloudwatchAppRegion: 'us-east-1',

  // App config data
  s3BucketName: 'otto-ui-config-prod',
  s3BucketRegion: 'us-east-1',

  // APM
  userOrgsGridName: 'SUOTAM',
  workOrderTypeToScreenIdMapGridName: 'WUTSCR',
};
