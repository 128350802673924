import React, { ReactElement } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { appRoutes } from '@/library/constants/appRoutes';
import Administration from '@/pages/Administration/Administration';
import Login from '@/pages/Login/Login';
import Overview from '@/pages/Overview/Overview';
import ScheduleWorkOrders from '@/pages/ScheduleWorkOrders/ScheduleWorkOrders';

import ProtectedAppLayout from './ProtectedAppLayout';
import PublicAppLayout from './PublicAppLayout';

function OttoRoutes(): ReactElement {
  const router = createBrowserRouter([
    {
      path: appRoutes.login,
      element: <PublicAppLayout />,
      children: [
        {
          index: true,
          element: <Login />,
        },
      ],
    },
    {
      path: appRoutes.overview,
      element: <ProtectedAppLayout />,
      children: [
        {
          index: true,
          path: appRoutes.overview,
          element: <Overview />,
        },
        {
          path: appRoutes.scheduleWorkOrders,
          element: <ScheduleWorkOrders />,
        },
        {
          path: appRoutes.administration,
          element: <Administration />,
        },
        {
          path: '/*',
          element: (
            <Navigate
              to={appRoutes.overview}
              replace
            />
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default OttoRoutes;
