import React, { ReactElement } from 'react';

import PolarisToggle, {
  ToggleProps,
} from '@amzn/awsui-components-react/polaris/toggle';

interface iCheckboxProps extends ToggleProps {}

export function Toggle(props: iCheckboxProps): ReactElement {
  return <PolarisToggle {...props} />;
}
