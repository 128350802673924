import React, { ReactElement } from 'react';

import PolarisTabs, {
  TabsProps,
} from '@amzn/awsui-components-react/polaris/tabs';

interface iTabProps extends TabsProps {}

export function Tabs(props: iTabProps): ReactElement {
  return <PolarisTabs {...props} />;
}
