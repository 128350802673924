export const concatPercent = (value?: number | string): string =>
  value != null ? `${value}%` : '';

export const formUiStringWithParameter = (string, params): string => {
  let updatedString = string;
  Object.keys(params).forEach(paramName => {
    updatedString = updatedString.replaceAll(
      `{${paramName}}`,
      `${params[paramName]}`,
    );
  });
  return updatedString;
};
