import React, { ReactElement } from 'react';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Icon from '@/library/components/atoms/Icon';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { isShiftLaborRollupValid } from '@/library/storeSlices/scheduleWOData/scheduleWODataUtils';
import { roundOffToDecimalPlaces } from '@/library/utils/numberUtils';

interface iShiftLaborRollupProps {
  woData: iFormattedRecommendationsData;
}

export function ShiftLaborRollup(props: iShiftLaborRollupProps): ReactElement {
  const { woData } = props;

  const { shiftLaborRollupData } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const shiftLaborRollup =
    woData.shift && woData.workOrderOwner
      ? shiftLaborRollupData[woData.scheduledStartDate]?.[woData.shift][
          woData.workOrderOwner
        ]
      : null;

  return woData.scheduledStartDate && !woData.excludedFromRollUpCalculation ? (
    <>
      {woData.shift && shiftLaborRollup?.maximumAllowedHours ? (
        <div
          className={
            !shiftLaborRollup || isShiftLaborRollupValid(shiftLaborRollup)
              ? ''
              : 'shift-labor-rollup-error'
          }
        >
          <span>
            {roundOffToDecimalPlaces(shiftLaborRollup?.allocatedHours ?? 0, 2)}/
            {roundOffToDecimalPlaces(shiftLaborRollup?.maximumAllowedHours, 2)}{' '}
            hrs
          </span>
          {!isShiftLaborRollupValid(shiftLaborRollup) && (
            <span className="error-icon">
              <Icon
                name="status-warning"
                variant="error"
              />
            </span>
          )}
        </div>
      ) : (
        <div>-</div>
      )}

      {[...Array(woData.requiredTechniciansCount - 1).keys()].map(index => {
        const secondaryOwnerShift = woData.secondaryOwnersShifts[index] ?? '';
        const secondaryOwner = woData.secondaryOwners[index] ?? '';
        const secondaryShiftLaborRollup = secondaryOwnerShift
          ? shiftLaborRollupData[woData.scheduledStartDate]?.[
              secondaryOwnerShift
            ][secondaryOwner]
          : null;

        return secondaryOwnerShift && secondaryShiftLaborRollup ? (
          <div
            key={secondaryOwnerShift + secondaryOwner + index}
            className={
              'secondary-labor-rollup ' +
              (!secondaryShiftLaborRollup ||
              isShiftLaborRollupValid(secondaryShiftLaborRollup)
                ? ''
                : 'shift-labor-rollup-error')
            }
          >
            <span>
              {roundOffToDecimalPlaces(
                secondaryShiftLaborRollup?.allocatedHours ?? 0,
                2,
              )}
              /
              {roundOffToDecimalPlaces(
                secondaryShiftLaborRollup?.maximumAllowedHours,
                2,
              )}{' '}
              hrs
            </span>
            {!isShiftLaborRollupValid(secondaryShiftLaborRollup) && (
              <span className="error-icon">
                <Icon
                  name="status-warning"
                  variant="error"
                />
              </span>
            )}
          </div>
        ) : (
          <div
            key={secondaryOwnerShift + secondaryOwner + index}
            className="secondary-labor-rollup "
          >
            -
          </div>
        );
      })}
    </>
  ) : (
    <></>
  );
}
