export const sortByProperty = (
  array: Array<Record<string, any>>,
  property: string,
  dataType: 'number' | 'string' | 'ISO_8601' = 'string',
): any[] => {
  return array.sort((a, b) => {
    if (dataType === 'ISO_8601') {
      const dateA = new Date(a[property]).getTime();
      const dateB = new Date(b[property]).getTime();

      return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    }
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  });
};
