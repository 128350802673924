import React, { ReactElement } from 'react';

import PolarisModal, {
  ModalProps,
} from '@amzn/awsui-components-react/polaris/modal';

export interface iModalProps extends ModalProps {}

export function Modal(props: iModalProps): ReactElement {
  const { visible } = props;
  return visible ? <PolarisModal {...props} /> : <></>;
}
