import { uiStrings } from '@/library/constants/uiStrings';

export const columnDefinition = [
  {
    id: 'woID',
    header: uiStrings.woId,
    cell: e => e.woID,
    width: 170,
    sortingComparator: (a, b) =>
      parseFloat(a.woID) > parseFloat(b.woID) ? 1 : -1,
  },
  {
    id: 'startDate',
    header: uiStrings.schStartDate,
    cell: e => e.startDate,
    width: 180,
    sortingComparator: (a, b) => {
      if (!a.startDate) return -1;
      if (!b.startDate) return 1;

      return new Date(a.startDate).getTime() > new Date(b.startDate).getTime()
        ? 1
        : -1;
    },
  },
  {
    id: 'woDesc',
    header: uiStrings.woDescription,
    cell: e => e.woDesc,
    sortingField: 'woDesc',
  },
  {
    id: 'equipmentDesc',
    header: uiStrings.equipmentDescription,
    cell: e => e.equipmentDesc,
    sortingField: 'equipmentDesc',
  },
  {
    id: 'precedenceScore',
    header: uiStrings.precedenceScore,
    cell: e => e.precedenceScore,
    width: 180,
    sortingComparator: (a, b) =>
      parseFloat(a.precedenceScore) > parseFloat(b.precedenceScore) ? 1 : -1,
  },
  {
    id: 'estLabor',
    header: uiStrings.estLabor,
    cell: e => e.estLabor,
    width: 150,
    sortingComparator: (a, b) =>
      parseFloat(a.estLabor) > parseFloat(b.estLabor) ? 1 : -1,
  },
];
