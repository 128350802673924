import { API, Auth, Storage } from 'aws-amplify';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { serviceNames } from '@/library/constants/apiConstants';
import { appRoutes } from '@/library/constants/appRoutes';
import { metricNames } from '@/library/constants/metricConstants';
import {
  currentStageOriginURL,
  envData,
} from '@/library/utils/environmentUtils';
import { recordLatencyEvent } from '@/library/utils/metricsUtils';

export const amplifyAuthConfig = {
  region: envData.cognitoAuthRegion,
  oauth: {
    domain: envData.cognitoAuthDomain,
    scope: ['email', 'openid'],
    responseType: 'code',
    redirectSignIn: currentStageOriginURL + appRoutes.login + '?auth=sso',
    redirectSignOut: currentStageOriginURL + '/logout',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  identityPoolId: envData.cognitoAuthIdentityPoolID,
  userPoolId: envData.cognitoAuthUserPoolID,
  userPoolWebClientId: envData.cognitoAuthUserPoolWebClientID,
};

export const amplifyStorageConfig = {
  AWSS3: {
    bucket: envData.s3BucketName,
    region: envData.s3BucketRegion,
  },
};
export const amplifyAPIConfig = {
  endpoints: [
    {
      name: serviceNames.heimdall,
      endpoint: envData.heimdallBaseURL,
      region: envData.heimdallRegion,
    },
    {
      name: serviceNames.utility,
      endpoint: envData.utilityServiceBaseURL,
      region: envData.utilityServiceRegion,
    },
    {
      name: serviceNames.ottoOverview,
      endpoint: envData.ottoOverviewBaseUrl,
      region: envData.ottoOverviewRegion,
    },
    {
      name: serviceNames.ottoOverview,
      endpoint: envData.ottoOverviewBaseUrl,
      region: envData.ottoOverviewRegion,
    },
    {
      name: serviceNames.ottoRecommendationManagementService,
      endpoint: envData.ottoRecommendationManagementServiceBaseUrl,
      region: envData.ottoRecommendationManagementServiceRegion,
    },
  ],
};

export const configureAmplifyServices = (): void => {
  API.configure(amplifyAPIConfig);
  Auth.configure(amplifyAuthConfig);
  Storage.configure(amplifyStorageConfig);
};

const getInitData = (initData): any => ({
  ...initData,
  queryStringParameters: {
    ...initData.queryStringParameters,
    source: 'APM',
  },
});

const apiMethods = {
  get: 'get',
  put: 'put',
  post: 'post',
  del: 'del',
};

interface iAPIRoute {
  name: string;
  route: string;
}

export const hitAPI = (
  method,
  serviceName,
  apiRoute: iAPIRoute,
  init = {},
): Promise<any> => {
  const emitMetric = recordLatencyEvent(
    `${metricNames.api_latency_}${serviceName}_${apiRoute.name}`,
  );
  emitMetric.setStartPoint();
  return API[method](serviceName, apiRoute.route, getInitData(init))
    .then(response => {
      emitMetric.setEndPoint();
      return response;
    })
    .catch(err => {
      recordEvent(
        `${metricNames.otto_apiFailure_}${serviceName}_${apiRoute.name}`,
        {
          statusCode: err?.response?.status ?? err?.status,
          statusText: err?.response?.statusText ?? err?.statusText,
          responseData: err?.response?.data ?? err?.data,
        },
      );
      throw err;
    });
};

export const cancelAPI = (apiPromise, errorMessage): boolean =>
  API.cancel(apiPromise, errorMessage);

type iQueryStringParameters = Record<string, any>;

export const heimdallAPI = {
  get: (apiRoute: iAPIRoute): Promise<any> =>
    hitAPI(apiMethods.get, serviceNames.heimdall, apiRoute),
  put: (apiRoute: iAPIRoute, body): Promise<any> =>
    hitAPI(apiMethods.put, serviceNames.heimdall, apiRoute, { body }),
  post: (apiRoute: iAPIRoute, body): Promise<any> =>
    hitAPI(apiMethods.post, serviceNames.heimdall, apiRoute, { body }),
};

export const utilityServiceAPI = {
  get: (
    apiRoute: iAPIRoute,
    queryStringParameters?: iQueryStringParameters,
  ): Promise<any> =>
    hitAPI(apiMethods.get, serviceNames.utility, apiRoute, {
      queryStringParameters,
    }),
  post: (apiRoute: iAPIRoute, body): Promise<any> =>
    hitAPI(apiMethods.post, serviceNames.utility, apiRoute, { body }),
};

export const otherServiceAPI = {
  get: (apiRoute: iAPIRoute): Promise<any> => {
    const emitMetric = recordLatencyEvent(
      `${metricNames.api_latency_}${serviceNames.otherServices}_${apiRoute.name}`,
    );
    emitMetric.setStartPoint();
    return fetch(apiRoute.route).then(res => {
      emitMetric.setEndPoint();
      return res.json();
    });
  },
  put: (apiRoute: iAPIRoute, body): Promise<any> => {
    const emitMetric = recordLatencyEvent(
      `${metricNames.api_latency_}${serviceNames.otherServices}_${apiRoute.name}`,
    );
    emitMetric.setStartPoint();
    return fetch(apiRoute.route, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(response => {
      emitMetric.setEndPoint();
      if (response.status > 210) {
        throw Error(JSON.stringify(response));
      }
      return response;
    });
  },
};

export const overviewServiceAPI = {
  get: (
    apiRoute: iAPIRoute,
    queryStringParameters?: iQueryStringParameters,
  ): Promise<any> =>
    hitAPI(apiMethods.get, serviceNames.ottoOverview, apiRoute, {
      queryStringParameters,
    }),
};

export const ottoRecommendationManagementServiceAPI = {
  post: (apiRoute: iAPIRoute, body): Promise<any> =>
    hitAPI(
      apiMethods.post,
      serviceNames.ottoRecommendationManagementService,
      apiRoute,
      { body },
    ),
  get: (
    apiRoute: iAPIRoute,
    queryStringParameters?: iQueryStringParameters,
  ): Promise<any> =>
    hitAPI(
      apiMethods.get,
      serviceNames.ottoRecommendationManagementService,
      apiRoute,
      {
        queryStringParameters,
      },
    ),
};
