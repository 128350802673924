import React, { ReactElement } from 'react';

import Button from '@/library/components/atoms/Button';
import Modal, { iModalProps } from '@/library/components/atoms/Modal';
import { uiStrings } from '@/library/constants/uiStrings';

import './confirmationModalStyles.scss';

interface iConfirmationModalProps extends iModalProps {
  onDismiss?: (e) => void;
  onConfirm?: (e) => void;
  hideConfirmButton?: boolean;
  confirmButtonLabel?: string;
  actionButtons?: ReactElement;
}

export function ConfirmationModal(
  props: iConfirmationModalProps,
): ReactElement {
  const {
    onDismiss,
    onConfirm,
    hideConfirmButton,
    confirmButtonLabel,
    actionButtons,
  } = props;

  return (
    <Modal
      footer={
        <div className="confirmation-buttons-container">
          {actionButtons ?? (
            <>
              <Button onClick={onDismiss}>{uiStrings.close}</Button>
              {!hideConfirmButton && (
                <Button
                  variant="primary"
                  onClick={onConfirm}
                >
                  {confirmButtonLabel ?? uiStrings.confirm}
                </Button>
              )}
            </>
          )}
        </div>
      }
      {...props}
    />
  );
}
