import { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';

import { appRoutes } from '@/library/constants/appRoutes';
import { uiStrings } from '@/library/constants/uiStrings';

export const overviewTab: TabsProps.Tab = {
  label: uiStrings.overviewTabLabel,
  id: appRoutes.overview,
};

export const administrationTab: TabsProps.Tab = {
  label: uiStrings.administrationTabLabel,
  id: appRoutes.administration,
};

export const scheduleWOTab: TabsProps.Tab = {
  label: uiStrings.scheduleWorkOrder,
  id: appRoutes.scheduleWorkOrders,
};
