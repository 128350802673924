export const statusCodes = {
  unauthorised: 401,
  notFound: 404,
};

export const serviceNames = {
  heimdall: 'Heimdall',
  utility: 'Utility',
  ottoOverview: 'Overview',
  ottoRecommendationManagementService: 'OttoRecommendationManagementService',
  otherServices: 'OtherServices',
};

export const apiRoutes = {
  validateAPMSessionIdAndGetUserDetails: '/users/validate',
  getAllTechniciansForSite:
    '/otto-utility/sites/{site}/model-run-date/{modelRunDate}/technicians',
  getRecommendationMetadataForSite:
    '/otto-recommendation/recommendation-metadatas/sites/{site}',
  getMisconfigurationDataForSite:
    '/otto-utility/sites/{site}/misconfigured-data',
  getTechniciansConfigurationForWO:
    '/otto-utility/sites/{site}/model-run-date/{modelRunDate}/technicians-configuration', // queryParams: techAvailabilityDate: yyyy-mm-dd | assets: Comma separated List str1,str2,str3'
  getAllShiftsForSite:
    '/otto-utility/sites/{site}/model-run-date/{modelRunDate}/shifts', // queryParams: scheduleStartDates: yyyy-mm-dd - Comma separated List str1,str2,str3
  overviewData: '/overview/forecasted-data/sites/{site}',
  ottoSites: '/overview/otto-sites',
  acquireEditLock: '/otto-recommendations/lock/acquire',
  resetEditLock: '/otto-recommendations/lock/heartbeat',
  releaseEditLock: '/otto-recommendations/lock/release',
  endorsementSubmission: '/otto-recommendation/endorsements/sites/{site}',
  getEndorsementGenerationStatus: '/recommendation-generation/sites/{site}',
};

export const apiNames = {
  validateAPMSessionIdAndGetUserDetails:
    'validateAPMSessionIdAndGetUserDetails',
  getAllTechniciansForSite: 'getAllTechniciansForSite',
  getMisconfigurationDataForSite: 'getMisconfigurationDataForSite',
  getEndorsementGenerationStatus: 'getEndorsementGenerationStatus',
  getTechniciansConfigurationForWO: 'getTechniciansConfigurationForWO',
  getAllShiftsForSite: 'getAllShiftsForSite',
  overviewData: 'overviewData',
  ottoSites: 'ottoSites',
  acquireEditLock: 'acquireEditLock',
  resetEditLock: 'resetEditLock',
  woRecommendationsFile: 'woRecommendationsFile',
  releaseEditLock: 'releaseEditLock',
  getRecommendationMetadataForSite: 'getRecommendationMetadataForSite',
  endorsementWOsDataS3Upload: 'endorsementWOsDataS3Upload',
  endorsementOverwriteReasonsS3Upload: 'endorsementOverwriteReasonsS3Upload',
};
