export const metricTypes = {
  click: 'click',
  latency: 'latency',
};

export const metricNames = {
  //App level metrics
  otto_pageVisit: 'otto_pageVisit',
  laborPlanningQuickSiteButton: 'laborPlanningQuickSiteButton',
  siteChange: 'siteChange',
  otto_appCrash: 'otto_appCrash',
  webVitals_: 'webVitals_',
  api_latency_: 'api_latency_',
  otto_apiFailure_: 'otto_apiFailure_',
  needHelpLinkClick: 'needHelpLinkClick',

  //Overview tab metrics
  overview_defaultSiteOnAppLoad: 'overview_defaultSiteOnAppLoad',
  overview_viewRecommendedChanges: 'overview_viewRecommendedChanges',
  overview_downloadCSV: 'overview_downloadCSV',
  overview_viewShiftUtilization: 'overview_viewShiftUtilization',

  //Administration tab metrics
  administration_updateConfigFile: 'administration_updateConfigFile',

  //SWO tab metrics
  swo_sessionStartSuccess: 'swo_sessionStartSuccess',
  swo_sessionStartFailed: 'swo_sessionStartFailed',
  swo_newRunAvailableRefreshScheduleConfirmation:
    'swo_newRunAvailableRefreshScheduleConfirmation',
  swo_endSession: 'swo_endSession',
  swo_loadNewRunOnUndoClick: 'swo_loadNewRunOnUndoClick',
  swo_undoToPreviousRun: 'swo_undoToPreviousRun',
  swo_undo: 'swo_undo',
  swo_loadNewRunOnEndorseClick: 'swo_loadNewRunOnEndorseClick',
  swo_applyFilter: 'swo_applyFilter',
  swo_refreshTable: 'swo_refreshTable',
  swo_downloadExcel: 'swo_downloadExcel',
  swo_applyTablePreferences: 'swo_applyTablePreferences',
  swo_endorsementSuccessful: 'swo_endorsementSuccessful',
  swo_endorsementFailed: 'swo_endorsementFailed',
  swo_idleSessionPrompt: 'swo_idleSessionPrompt',
  swo_maxIdleTimeExceeded: 'swo_maxIdleTimeExceeded',
  swo_workOrderIdClick: 'swo_workOrderIdClick',
};

export const endSessionReasons = {
  endSessionButtonClick: 'endSessionButtonClick',
  somethingWentWrongOnLockReset: 'somethingWentWrongOnLockReset',
  idleSessionTimeLimitExceeded: 'idleSessionTimeLimitExceeded',
  userChangedSite: 'userChangedSite',
};
