import React, { ReactElement } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import SelectDropdown from '@/library/components/atoms/SelectDropdown';
import { editWORecommendation } from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { InlineOverwriteTriggerButton } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/InlineOverwriteTriggerButton';

interface iShiftOverwriteInputProps {
  woData: iFormattedRecommendationsData;
  secondaryShiftIndex?: number;
  shiftError: boolean;
  shiftErrorMessage: string;
}

export function ShiftOverwriteInput(
  props: iShiftOverwriteInputProps,
): ReactElement {
  const { woData, secondaryShiftIndex, shiftError, shiftErrorMessage } = props;

  const dispatch = useAppDispatch();

  const { shiftListToDateMap, isFetchingAllShifts } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  const updateWOData = (woData): void => {
    dispatch(editWORecommendation(woData));
  };

  const isSecondaryShift = typeof secondaryShiftIndex === 'number';

  const shiftId = isSecondaryShift
    ? woData.secondaryOwnersShifts[secondaryShiftIndex]
    : woData.shift;

  const onOverwriteValue = (newValue): void => {
    if (!isSecondaryShift) {
      updateWOData({
        ...woData,
        shift: newValue,
        workOrderOwner: '',
      });
    } else {
      const updatedSecondaryOwnersShifts: string[] = [];
      const updatedSecondaryOwners: string[] = [];
      for (let i = 0; i < woData.requiredTechniciansCount - 1; i++) {
        if (secondaryShiftIndex === i) {
          updatedSecondaryOwnersShifts.push(newValue);
          updatedSecondaryOwners.push('');
        } else {
          updatedSecondaryOwnersShifts.push(woData.secondaryOwnersShifts[i]);
          updatedSecondaryOwners.push(woData.secondaryOwners?.[i] ?? '');
        }
      }

      updateWOData({
        ...woData,
        secondaryOwnersShifts: updatedSecondaryOwnersShifts,
        secondaryOwners: updatedSecondaryOwners,
      });
    }
  };

  return (
    <InlineOverwriteTriggerButton value={shiftId}>
      <SelectDropdown
        buttonType="inline"
        selectedOption={{
          label: shiftId,
          value: shiftId,
        }}
        onChange={({ detail }) => onOverwriteValue(detail.selectedOption.value)}
        options={
          shiftError ? [] : shiftListToDateMap[woData.scheduledStartDate]
        }
        labelKey={'shiftId'}
        valueKey={'shiftId'}
        loading={isFetchingAllShifts}
        error={shiftError}
        errorText={shiftErrorMessage}
      />
    </InlineOverwriteTriggerButton>
  );
}
