import React, { ReactElement, useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';
import { Navigate, Outlet } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Spinner from '@/library/components/atoms/Spinner';
import StatusIndicator from '@/library/components/atoms/StatusIndicator';
import CommonHeader from '@/library/components/organisms/CommonHeader';
import { NavBar } from '@/library/components/organisms/NavBar/NavBar';
import { appConstants } from '@/library/constants/appConstants';
import { appRoutes } from '@/library/constants/appRoutes';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  fetchOttoSites,
  fetchUserAuthorisedSites,
  updateOttoAppConfig,
} from '@/library/storeSlices/masterData/masterDataActions';
import { getSessionIdAndUserIdFromSearchParams } from '@/library/utils/urlUtils';

const { sessionId } = getSessionIdAndUserIdFromSearchParams();

function ProtectedAppLayout(): ReactElement {
  const [isUserAuthorised, setIsUserAuthorised] = useState<boolean>();
  const dispatch = useAppDispatch();
  const [isFetchingOttoAppConfig, setIsFetchingOttoAppConfig] = useState(false);
  const [ottoAppConfigFetched, setOttoAppConfigFetched] = useState(false);

  const {
    ottoAppConfig,
    userDetails,
    isFetchingOttoSites,
    isFetchingUserAuthorisedSites,
  } = useAppSelector(state => state.masterDataReducer);

  const { overviewTabAuthorisedGroups } = ottoAppConfig;

  const isAuthenticated =
    userDetails?.userId && (!sessionId || sessionId === userDetails.sessionId);

  const fetchOttoAppConfig = (): void => {
    setIsFetchingOttoAppConfig(true);
    const { readRemoteFile } = usePapaParse();
    Storage.get(appConstants.ottoAppConfigFileName, {
      level: 'public',
    }).then(url => {
      readRemoteFile(url, {
        complete: results => {
          dispatch(updateOttoAppConfig(results.data));
          setIsFetchingOttoAppConfig(false);
          setOttoAppConfigFetched(true);
        },
        error: () => {
          setIsFetchingOttoAppConfig(false);
          setOttoAppConfigFetched(true);
        },
        header: true,
        download: true,
      });
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (!isFetchingOttoAppConfig && !ottoAppConfigFetched) {
        fetchOttoAppConfig();
      } else if (!isFetchingOttoAppConfig && ottoAppConfigFetched) {
        const userGroup = userDetails?.userGroup ?? '';
        if (
          overviewTabAuthorisedGroups.includes(userGroup) &&
          !isFetchingOttoAppConfig
        ) {
          setIsUserAuthorised(true);
          dispatch(fetchOttoSites());
          dispatch(fetchUserAuthorisedSites(userDetails?.userId));
        } else {
          setIsUserAuthorised(false);
        }
      }
    }
  }, [isFetchingOttoAppConfig, userDetails, ottoAppConfig]);

  const displayLoader = (message): ReactElement => (
    <>
      <NavBar />
      <div className="protected-layout-message-container">
        <Spinner size="large" />
        {message}
      </div>
    </>
  );

  return isAuthenticated ? (
    !isFetchingOttoAppConfig ? (
      isUserAuthorised ? (
        isFetchingOttoSites && isFetchingUserAuthorisedSites ? (
          displayLoader(uiStrings.fetchingAppConfiguration)
        ) : (
          <>
            <NavBar authenticated />
            <CommonHeader />
            <div className="protected-app-layout-container">
              <Outlet />
            </div>
          </>
        )
      ) : (
        <>
          <NavBar />
          <div className="protected-layout-message-container">
            <StatusIndicator
              type="error"
              colorOverride="grey"
            >
              {uiStrings.userNotAuthorisedError}
            </StatusIndicator>
          </div>
        </>
      )
    ) : (
      displayLoader(uiStrings.fetchingAppConfiguration)
    )
  ) : (
    <Navigate to={appRoutes.login + location.search} />
  );
}

export default ProtectedAppLayout;
