import React, { ReactElement, useEffect, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button';
import StepProgress from '@/library/components/molecules/StepProgress';
import { uiStrings } from '@/library/constants/uiStrings';
import { validateAPMSessionIdAndFetchUserDetails } from '@/library/storeSlices/masterData/masterDataActions';
import { currentAuthenticatedUser } from '@/library/utils/authUtils';

export default function LoginStagesContainer(): ReactElement {
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const dispatch = useAppDispatch();

  const userDetailsError = useAppSelector(
    state => state.masterDataReducer.userDetailsError,
  );

  useEffect(() => {
    initiateAuth();
  }, []);

  const initiateAuth = (): void => {
    setIsAuthInProgress(true);
    currentAuthenticatedUser().finally(() => {
      dispatch(validateAPMSessionIdAndFetchUserDetails()).finally(() => {
        setIsAuthInProgress(false);
      });
    });
  };

  return (
    <>
      <StepProgress
        loading={isAuthInProgress}
        failed={!!userDetailsError?.message}
        stepLabel={
          uiStrings.verifyingOttoUser +
          (userDetailsError?.message && !isAuthInProgress
            ? ` ( ${uiStrings.status} ${userDetailsError?.status} - ${userDetailsError?.message} )`
            : '')
        }
      />
      {!!userDetailsError?.message && (
        <Button
          onClick={initiateAuth}
          loading={isAuthInProgress}
        >
          {uiStrings.retry}
        </Button>
      )}
    </>
  );
}
