import React, { ReactElement } from 'react';

import Grid from '@amzn/awsui-components-react/polaris/grid';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import BacklogDaysCard from '@/library/components/molecules/DaysOfBacklogCard';
import OverviewCardsList from '@/library/components/organisms/OverviewCardsList';

export function OverviewContentSection(): ReactElement {
  const forecastedData = useAppSelector(
    state => state.overviewDataReducer.siteData,
  );
  const siteName = useAppSelector(
    state => state.masterDataReducer.activeSite?.siteId,
  );
  const daysOfBacklog =
    siteName && forecastedData[siteName]?.daysOfBacklog.length
      ? forecastedData[siteName]?.daysOfBacklog[0]?.metricValue
      : '-';

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, xs: 12, s: 9, m: 9 } },
        { colspan: { default: 0, xs: 0, s: 3, m: 3 } },
      ]}
      data-testid="overviewCardsList"
    >
      <OverviewCardsList />
      {daysOfBacklog > 0 ||
      (siteName && forecastedData[siteName]?.utilizationDetails.length) ? (
        <BacklogDaysCard daysOfBacklog={daysOfBacklog} />
      ) : (
        <></>
      )}
    </Grid>
  );
}
