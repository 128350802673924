import {
  heimdallAPI,
  overviewServiceAPI,
} from '@/configs/apiServiceConfig/apiServiceConfig';
import { apiNames, apiRoutes } from '@/library/constants/apiConstants';
import { authSignIn } from '@/library/utils/authUtils';
import { getSessionIdAndUserIdFromSearchParams } from '@/library/utils/urlUtils';

export const validateAPMSessionIdAndGetUserDetails = (): Promise<any> => {
  const { userId, sessionId, tenantId } =
    getSessionIdAndUserIdFromSearchParams();
  const bodyParams = {
    userId,
    clientId: 'OTTO_APM',
    sessionId,
  };
  return userId && sessionId
    ? heimdallAPI
        .post(
          {
            name: apiNames.validateAPMSessionIdAndGetUserDetails,
            route: apiRoutes.validateAPMSessionIdAndGetUserDetails,
          },
          bodyParams,
        )
        .then(async response => {
          await authSignIn(response?.userDetails?.email ?? userId, sessionId);
          return { ...response, tenantId };
        })
    : Promise.reject(Error(''));
};

export const getOttoSites = (): Promise<any> =>
  overviewServiceAPI.get({
    name: apiNames.ottoSites,
    route: apiRoutes.ottoSites,
  });
