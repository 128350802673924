import React, { ReactElement } from 'react';

import './comparisonChartHeaderStyles.scss';

export interface iChartHeaderProps {
  headerText: string;
  description: string;
}

export function ComparisonChartHeader(props: iChartHeaderProps): ReactElement {
  const { headerText, description } = props;

  return (
    <>
      <div className="chart-heading">
        <strong>{headerText}</strong>
      </div>
      <p className="chart-sub-heading"> {description}</p>
    </>
  );
}
