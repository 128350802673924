import { buildEncrypt } from '@aws-crypto/encrypt-browser';
import {
  KMS,
  KmsKeyringBrowser,
  getClient,
} from '@aws-crypto/kms-keyring-browser';
import { toBase64 } from '@aws-sdk/util-base64-browser';
import { Auth, Hub } from 'aws-amplify';

import { siteState } from '@/library/utils/enums';
import { envData } from '@/library/utils/environmentUtils';
import { sortByProperty } from '@/library/utils/objectUtils';

export async function encryptData(data: string): Promise<string> {
  const generatorKeyId = envData.kmsGeneratorKeyID;
  const keyIds = [generatorKeyId];

  const credentials = await Auth.currentCredentials();
  const { accessKeyId, secretAccessKey, sessionToken } =
    Auth.essentialCredentials(credentials);

  const clientProvider = getClient(KMS, {
    credentials: {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    },
  });

  const keyring = new KmsKeyringBrowser({
    clientProvider,
    generatorKeyId,
    keyIds,
  });

  const buffer = new TextEncoder().encode(data);
  const { result } = await buildEncrypt().encrypt(keyring, buffer);

  return toBase64(result);
}

interface iCallbackFunctions {
  signIn?: Function;
  signOut?: Function;
  customOAuthState?: Function;
  defaultCallback?: Function;
}

export const setupHubListener = (
  callbackFunctions: iCallbackFunctions,
): any => {
  return Hub.listen('auth', ({ payload: { event, data } }) => {
    if (callbackFunctions[event]) {
      callbackFunctions[event](data);
    } else if (callbackFunctions.defaultCallback) {
      callbackFunctions.defaultCallback(event, data);
    }
  });
};

export const checkCurrentAuthenticatedUser = (): Promise<any> =>
  Auth.currentAuthenticatedUser();

export const initiateFederatedSignIn = (): Promise<any> =>
  Auth.federatedSignIn({
    customProvider: 'Otto-Federate-IdentityProvider',
  });

export const authenticateUserViaSSO = (): Promise<any> =>
  checkCurrentAuthenticatedUser().catch(err => {
    initiateFederatedSignIn();
    return Promise.reject(err);
  });

export const authSignIn = (userId, password): Promise<any> =>
  Auth.signIn(userId, password);

export const currentUserCredentials = (): Promise<any> =>
  Auth.currentUserCredentials();

export const currentAuthenticatedUser = (): Promise<any> =>
  Auth.currentAuthenticatedUser();

export const getSectionizedSiteListAndDefaultSelectedSite = (
  ottoEnabledSites,
  userAuthorisedSites,
  activeSite,
  timezoneOffsetWithRespectToUTC,
): Record<string, any> => {
  let ottoEnabledUserAuthorizedSites: any[] = [];
  let ottoDisabledUserAuthorizedSites: any[] = [];
  userAuthorisedSites.forEach(siteId => {
    const siteData = ottoEnabledSites.find(
      enabledSite => enabledSite.siteId === siteId,
    );
    if (siteData) {
      ottoEnabledUserAuthorizedSites.push(siteData);
    } else {
      ottoDisabledUserAuthorizedSites.push({ siteId });
    }
  });

  const isActiveSiteIdPresent = !!ottoEnabledUserAuthorizedSites.find(
    site => site?.siteId === activeSite?.siteId,
  );
  ottoEnabledUserAuthorizedSites = sortByProperty(
    ottoEnabledUserAuthorizedSites,
    'siteId',
  );
  ottoDisabledUserAuthorizedSites = sortByProperty(
    ottoDisabledUserAuthorizedSites,
    'siteId',
  );

  return {
    sectionizedListOfSites: {
      ottoEnabledUserAuthorizedSites,
      ottoDisabledUserAuthorizedSites,
    },
    activeSite: {
      siteId:
        activeSite?.siteId && isActiveSiteIdPresent
          ? activeSite.siteId
          : ottoEnabledUserAuthorizedSites[0]?.siteId ?? '',
      utcOffset:
        activeSite?.utcOffset && isActiveSiteIdPresent
          ? activeSite.utcOffset
          : ottoEnabledUserAuthorizedSites[0]?.utcOffset +
              timezoneOffsetWithRespectToUTC ?? 0,
      state: siteState.ENABLED,
    },
  };
};
