import React, { ReactElement } from 'react';

import Card from '@/library/components/atoms/Card';
import LoginStagesContainer from '@/pages/Login/loginFrames/LoginStagesContainer/LoginStagesContainer';
import ottoLogo from '@/resources/images/otto-logo-black.png';

import './loginStyles.scss';

function Login(): ReactElement {
  return (
    <div className="login-container">
      <Card
        disableContentPaddings
        className="login-form-card"
      >
        <span className="otto-logo">
          <img
            src={ottoLogo}
            alt="Otto Logo"
            className="otto-logo-icon"
          />
        </span>

        <LoginStagesContainer />
      </Card>
    </div>
  );
}

export default Login;
