type iParams = Record<string, string>;

/* *
 * @param {string} endpoint - Can be a complete URL or just the route.
 *                            Param names must be wrapped in /{<paramName>}
 *                            ex: /otto-utility/sites/STL5/model-run-date/{modelRunDate}/technicians
 * @param {Record<string, string>} params - JSON Object with key as paramName and value as value to replace param with
 *                                          ex: {modelRunDate: '2023-12-21', shift: 'SH1'}
 * @returns {string} - paramNames will be replaced with paramValues in endpoint
 * */
export const updateEndpointWithPathParams = (
  endpoint: string,
  params: iParams,
): string => {
  let updatedEndpoint = endpoint;
  Object.keys(params).forEach(paramName => {
    updatedEndpoint = updatedEndpoint.replaceAll(
      `/{${paramName}}`,
      `/${params[paramName]}`,
    );
  });
  return updatedEndpoint;
};

interface iSearchParams {
  userId: string;
  sessionId: string;
  tenantId: string;
}

export const getSessionIdAndUserIdFromSearchParams = (): iSearchParams => {
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('userId') ?? '';
  const sessionId = searchParams.get('eamid') ?? '';
  const tenantId = searchParams.get('tenant') ?? '';
  return {
    userId,
    sessionId,
    tenantId,
  };
};
