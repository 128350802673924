import React, { ReactElement } from 'react';

import PolarisInput, {
  InputProps,
} from '@amzn/awsui-components-react/polaris/input';
import PolarisInputArea, {
  TextareaProps,
} from '@amzn/awsui-components-react/polaris/textarea';

export interface iInputProps extends InputProps, TextareaProps {
  textArea?: boolean;
}

export function Input(props: iInputProps): ReactElement {
  const { textArea } = props;
  return textArea ? (
    <PolarisInputArea {...props} />
  ) : (
    <PolarisInput {...props} />
  );
}
