import React, { ReactElement } from 'react';

import Popover, {
  PopoverProps,
} from '@amzn/awsui-components-react/polaris/popover';

export interface iTooltipProps extends PopoverProps {}

export function Tooltip(props: PopoverProps): ReactElement {
  return <Popover {...props} />;
}
