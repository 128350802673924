import React, { ReactElement } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Icon from '@/library/components/atoms/Icon';
import { sortWORecommendations } from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';

import './tableColumnHeaderStyles.scss';

interface iTableColumnHeaderProps {
  headerText: string | ReactElement;
  sortingComparator?: (a, b) => number;
  columnId: string;
  sortingField?: string;
}

function TableColumnHeader(props: iTableColumnHeaderProps): ReactElement {
  const { headerText, sortingComparator, columnId, sortingField } = props;
  const dispatch = useAppDispatch();
  const { sortedColumn } = useAppSelector(state => state.scheduleWODataReducer);

  const { columnId: sortedColumnId, isAscending } = sortedColumn;

  return (
    <div
      className="column-header-container"
      onClick={() =>
        dispatch(
          sortWORecommendations({
            sortingComparator:
              sortingComparator ??
              (sortingField &&
                ((a, b) => {
                  if (a[sortingField] === b[sortingField]) return 0;
                  if (!a[sortingField]) return -1;
                  if (!b[sortingField]) return 1;
                  return a[sortingField] > b[sortingField] ? 1 : -1;
                })),
            columnId,
          }),
        )
      }
    >
      {headerText}{' '}
      <Icon
        name={
          sortedColumnId !== columnId
            ? 'caret-down'
            : isAscending
            ? 'caret-up-filled'
            : 'caret-down-filled'
        }
      />
    </div>
  );
}

export default TableColumnHeader;
