export const throttle = (mainFunction, delay): any => {
  let timerFlag: ReturnType<typeof setTimeout> | null = null;

  return (...args) => {
    if (timerFlag === null) {
      mainFunction(...args);
      timerFlag = setTimeout(() => {
        timerFlag = null;
      }, delay);
    }
  };
};
