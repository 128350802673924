import React, { ReactElement, useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';

import Button from '@/library/components/atoms/Button';
import Modal from '@/library/components/atoms/Modal';
import StatusIndicator from '@/library/components/atoms/StatusIndicator';
import Table from '@/library/components/atoms/Table';
import CsvDownloader from '@/library/components/molecules/CSVDownloader';
import Tooltip from '@/library/components/molecules/Tooltip';
import { columnDefinition } from '@/library/components/organisms/OverviewRecommendedChangesTableModal/columnDefinition';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { iUtilizationData } from '@/library/storeSlices/overviewData/overviewDataTypes';
import { formatDate, getUTCDateByDateString } from '@/library/utils/dateUtils';
import { formUiStringWithParameter } from '@/library/utils/stringUtils';
import {
  generateCSVDataForRecommendedChanges,
  isLaborOverUtilized,
  sortRecommendedWOsList,
} from '@/pages/Overview/overviewUtils';

import './overviewRecommendedChangesTableModalStyles.scss';

interface iOverviewRecommendedChangesTableModalProps {
  visible: boolean;
  onDismiss: () => void;
  overviewData: iUtilizationData;
}

export function OverviewRecommendedChangesTableModal(
  props: iOverviewRecommendedChangesTableModalProps,
): ReactElement {
  const { visible, onDismiss, overviewData } = props;
  const {
    date,
    overallUtilization,
    hoursToAdjust,
    workOrdersRecommendationFilePresignedUrl,
  } = overviewData;
  const [tableData, setTableData] = useState<Array<Record<string, any>>>([]);
  const [isFetchingTableData, setIsFetchingTableData] = useState(false);

  const isOverUtilized = isLaborOverUtilized(overallUtilization);

  useEffect(() => {
    if (visible) {
      const { readRemoteFile } = usePapaParse();

      if (workOrdersRecommendationFilePresignedUrl) {
        setIsFetchingTableData(true);
        readRemoteFile(workOrdersRecommendationFilePresignedUrl, {
          error: () => {
            setIsFetchingTableData(false);
          },
          complete: results => {
            setIsFetchingTableData(false);

            const sortedData = sortRecommendedWOsList(
              results.data,
              hoursToAdjust,
              isOverUtilized,
            );
            setTableData(sortedData);
          },
          header: true,
          download: true,
        });
      }
    }
  }, [visible]);

  const formattedDate = formatDate(getUTCDateByDateString(date), '{MMMM} {DD}');

  return (
    <Modal
      size="max"
      disableContentPaddings={true}
      visible={visible}
      onDismiss={onDismiss}
      header={
        <div className="overview-recommendations-modal-header">
          <span className="header-text-container">
            <span className="header-text">
              {formUiStringWithParameter(
                isOverUtilized
                  ? uiStrings.recommendedWorkOrdersToRemoveForDate
                  : uiStrings.recommendedWorkOrdersAdditionForDate,
                {
                  date: formattedDate,
                },
              )}
            </span>
            <span>
              {uiStrings.labourUtilization}: {overallUtilization}%
            </span>
            <span>
              {isOverUtilized
                ? uiStrings.hoursToRemove
                : uiStrings.hoursAvailable}
              : {hoursToAdjust}
              <Tooltip
                dismissButton={false}
                position="top"
                size="large"
                triggerType="custom"
                content={
                  <div>
                    {isOverUtilized ? (
                      <>
                        <strong>
                          {uiStrings.hoursToRemoveDescriptionHeading}
                        </strong>
                        <p className="modal-tooltip-description">
                          {uiStrings.hoursToRemoveDescriptionPara1}
                        </p>
                        <p className="modal-tooltip-description">
                          {uiStrings.hoursToRemoveDescriptionPara2}
                        </p>
                      </>
                    ) : (
                      <>
                        <strong>
                          {uiStrings.hoursAvailableDescriptionHeading}
                        </strong>
                        <p className="modal-tooltip-description">
                          {uiStrings.hoursAvailableDescriptionPara}
                        </p>
                      </>
                    )}
                  </div>
                }
              >
                <Button
                  variant={'icon'}
                  iconName={'status-info'}
                />
              </Tooltip>
            </span>
          </span>
          <span>
            <CsvDownloader
              data={generateCSVDataForRecommendedChanges(
                tableData,
                isOverUtilized,
              )}
              filename={formattedDate + '_OttoRecommendedChanges'}
              metricId={metricNames.overview_downloadCSV}
            >
              {uiStrings.downloadCSV}
            </CsvDownloader>
          </span>
        </div>
      }
    >
      <Table
        columnDefinitions={[
          {
            id: 'recommended',
            header: uiStrings.recommended,
            cell: e =>
              e.recommended ? (
                <StatusIndicator type={isOverUtilized ? 'error' : 'success'}>
                  {isOverUtilized ? uiStrings.remove : uiStrings.recommended}
                </StatusIndicator>
              ) : null,
            width: 170,
            sortingComparator: (a, b) =>
              a.recommended && !b.recommended ? 1 : -1,
          },
          ...columnDefinition,
        ]}
        items={tableData}
        trackBy="workOrderID"
        header=""
        pageSize={12}
        loading={isFetchingTableData}
        loadingText={uiStrings.fetchingWorkOrders}
      />
    </Modal>
  );
}
