import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button';
import MultiSelectDropdown from '@/library/components/atoms/MultiSelectDropdown';
import Toggle from '@/library/components/atoms/Toggle';
import { appConstants } from '@/library/constants/appConstants';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  fetchRecommendationGenerationStatusForSite,
  resetFilteredWORecommendationsTableList,
  updateAppliedTableFilters,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import {
  filterKeys,
  initialAppliedFilters,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataSlice';
import { iFilterKeys } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import {
  addNoOfDaysToDate,
  formatDate,
  getDatesInRange,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { recordSWOEvent } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

import './tableFiltersStyles.scss';

enum filterActions {
  clear = 'clear',
  apply = 'apply',
}

function TableFilters(): ReactElement {
  const dispatch = useAppDispatch();

  const {
    shiftList,
    isFetchingAllShifts,
    fetchAllShiftsError,
    isFetchingAllTechnicians,
    fetchAllTechniciansError,
    techniciansList,
    appliedFilters,
    recommendationsMetadata,
    isFetchingWORecommendations,
  } = useAppSelector(state => state.scheduleWODataReducer);

  const { ottoAppConfig } = useAppSelector(state => state.masterDataReducer);

  const [selectedFilters, setSelectedFilters] = useState<iFilterKeys>(
    initialAppliedFilters,
  );

  const [isSelectionChanged, setIsSelectionChanged] = useState<boolean>(false);

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const onChangeSelectedFilter = (key): (({ detail }) => void) =>
    useCallback(
      ({ detail }) => {
        const updatedFilters = {
          ...selectedFilters,
          [key]: detail.selectedOptions,
        };
        setSelectedFilters(updatedFilters);
        setIsSelectionChanged(true);
      },
      [selectedFilters],
    );

  const getPlaceholderValue = (prefixLabel, appliedData, allData): string =>
    useMemo(() => {
      const prefix = prefixLabel + ': ';
      const trimmedLabel =
        appliedData?.[0]?.label?.length > 18
          ? `${appliedData?.[0]?.label.slice(0, 18)}...`
          : appliedData?.[0]?.label;
      if (appliedData.length === 0) {
        return prefix + uiStrings.all;
      } else if (appliedData.length === 1) {
        return prefix + trimmedLabel;
      } else {
        return prefix + trimmedLabel + ' +' + (appliedData.length - 1);
      }
    }, [prefixLabel, appliedData, allData]);

  const handleApplyFilter = (action: string): (() => void) =>
    useCallback(() => {
      dispatch(fetchRecommendationGenerationStatusForSite());
      recordSWOEvent(metricNames.swo_applyFilter, { filterAction: action });
      if (action === filterActions.clear) {
        const resetFilters = {
          ...initialAppliedFilters,
          hideErrors: selectedFilters.hideErrors,
        };
        setSelectedFilters(resetFilters);
        dispatch(updateAppliedTableFilters(resetFilters));
      } else {
        dispatch(updateAppliedTableFilters(selectedFilters));
      }

      dispatch(resetFilteredWORecommendationsTableList());
      setIsSelectionChanged(false);
    }, [selectedFilters]);

  const onToggleHideErrors = ({ detail: { checked } }): void => {
    recordSWOEvent(metricNames.swo_applyFilter, {
      filterAction: checked ? 'hideWOWithErrors' : 'showWOWithErrors',
    });

    dispatch(fetchRecommendationGenerationStatusForSite());

    const hideErrors = checked ? [true] : [];

    const updatedSelectedFilters = {
      ...selectedFilters,
      hideErrors,
    };
    setSelectedFilters(updatedSelectedFilters);

    const updatedAppliedFilters = {
      ...appliedFilters,
      hideErrors,
    };

    dispatch(updateAppliedTableFilters(updatedAppliedFilters));
    dispatch(resetFilteredWORecommendationsTableList());
  };

  const formattedTechniciansList = useMemo(
    () => techniciansList.map(tech => ({ label: tech, value: tech })),
    [techniciansList],
  );

  const isAnyFilterApplied = useMemo(
    () =>
      Object.keys(appliedFilters).some(
        filterKey =>
          filterKey !== filterKeys.hideErrors &&
          appliedFilters[filterKey].length !== 0,
      ),
    [appliedFilters],
  );

  const modelRunDate = getUTCDateByDateString(
    recommendationsMetadata.modelRunDate ?? '',
  );

  const listOfDatesForFilters = getDatesInRange(
    modelRunDate,
    addNoOfDaysToDate(
      modelRunDate,
      ottoAppConfig.numberOfDatesForSchedulingWorkOrder - 1,
    ),
  ).map(date => ({
    label: formatDate(date, appConstants.uiDateFormat),
    value: formatDate(date, appConstants.dateFormat),
  }));

  const scheduleStartDateFilterOptions = [
    {
      label: uiStrings.unscheduled,
      value: null,
    },
    ...listOfDatesForFilters,
  ];

  const dueDateFilterOptions = [
    {
      label: uiStrings.noDueDate,
      value: null,
    },
    {
      label: uiStrings.pastDates,
      value: 'past~' + listOfDatesForFilters[0]?.value,
    },
    ...listOfDatesForFilters,
    {
      label: uiStrings.beyond,
      value:
        'beyond~' +
        listOfDatesForFilters[listOfDatesForFilters.length - 1]?.value,
    },
  ];

  const formattedSchedulingBlocks = ottoAppConfig.schedulingBlocksList.map(
    block => ({
      label: block,
      value: block,
    }),
  );

  const formattedOttoStatusTypes = Object.keys(
    ottoAppConfig.workOrderOttoStatusTypes,
  ).map(status => ({
    label: status,
    value: status,
  }));

  const formattedUnassignedReasons = Object.values(
    ottoAppConfig.unassignedReasonCodeToDescriptionMapping,
  ).map(reason => ({
    label: reason,
    value: reason,
  }));

  return (
    <div
      className={`filters-container ${
        isFetchingWORecommendations ? 'disable' : ''
      }`}
    >
      <div className="filter-dropdown-container">
        <div className="filter-dropdown-container">
          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.schStartDate] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.schStDate,
              selectedFilters[filterKeys.schStartDate] ?? [],
              scheduleStartDateFilterOptions,
            )}
            options={scheduleStartDateFilterOptions}
            onChange={onChangeSelectedFilter(filterKeys.schStartDate)}
          />

          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.dueDate] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.dueDate,
              selectedFilters[filterKeys.dueDate] ?? [],
              dueDateFilterOptions,
            )}
            options={dueDateFilterOptions}
            onChange={onChangeSelectedFilter(filterKeys.dueDate)}
          />

          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.shift] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.shift,
              selectedFilters[filterKeys.shift] ?? [],
              shiftList,
            )}
            options={shiftList}
            labelKey={'shiftId'}
            valueKey={'shiftId'}
            onChange={onChangeSelectedFilter(filterKeys.shift)}
            loading={isFetchingAllShifts}
            error={!!fetchAllShiftsError}
            errorText={fetchAllShiftsError}
          />
          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.woOwner] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.technicians,
              selectedFilters[filterKeys.woOwner] ?? [],
              formattedTechniciansList,
            )}
            options={formattedTechniciansList}
            onChange={onChangeSelectedFilter(filterKeys.woOwner)}
            loading={isFetchingAllTechnicians}
            error={!!fetchAllTechniciansError}
            errorText={fetchAllTechniciansError}
          />
          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.schBlock] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.woExecution,
              selectedFilters[filterKeys.schBlock] ?? [],
              formattedSchedulingBlocks,
            )}
            options={formattedSchedulingBlocks}
            onChange={onChangeSelectedFilter(filterKeys.schBlock)}
          />
          <MultiSelectDropdown
            selectedOptions={selectedFilters[filterKeys.ottoStatus] ?? []}
            placeholder={getPlaceholderValue(
              uiStrings.ottoStatus,
              selectedFilters[filterKeys.ottoStatus] ?? [],
              formattedOttoStatusTypes,
            )}
            options={formattedOttoStatusTypes}
            onChange={onChangeSelectedFilter(filterKeys.ottoStatus)}
          />
          <MultiSelectDropdown
            selectedOptions={
              selectedFilters[filterKeys.unassignedReasons] ?? []
            }
            placeholder={getPlaceholderValue(
              uiStrings.unassignedReason,
              selectedFilters[filterKeys.unassignedReasons] ?? [],
              formattedUnassignedReasons,
            )}
            options={formattedUnassignedReasons}
            onChange={onChangeSelectedFilter(filterKeys.unassignedReasons)}
          />
        </div>
        <div>
          <Button
            variant="primary"
            onClick={handleApplyFilter(filterActions.apply)}
            disabled={!isSelectionChanged}
          >
            {uiStrings.apply}
          </Button>
          <Button
            variant="link"
            onClick={handleApplyFilter(filterActions.clear)}
            disabled={!isAnyFilterApplied}
          >
            {uiStrings.clearFilters}
          </Button>
        </div>
      </div>
      <Toggle
        checked={!!selectedFilters[filterKeys.hideErrors].length}
        onChange={onToggleHideErrors}
      >
        {uiStrings.hideWOsWithErrors}
      </Toggle>
    </div>
  );
}

export default TableFilters;
