import React, { PropsWithChildren, ReactElement } from 'react';
import {
  ErrorBoundaryProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';

export function ErrorBoundary(
  props: PropsWithChildren<ErrorBoundaryProps>,
): ReactElement {
  return <ReactErrorBoundary {...props} />;
}
