import { createAsyncThunk } from '@reduxjs/toolkit';

import { getForecastedData } from '@/library/apiServices/overviewServices';
import { actionTypes } from '@/library/constants/actionTypes';

export const fetchForecastedData = createAsyncThunk(
  actionTypes.fetchForecastedData,
  (siteDetails: Record<string, any>, thunkAPI) =>
    getForecastedData(siteDetails, thunkAPI)
      .then(response => ({
        siteId: siteDetails.siteId,
        ...response,
      }))
      .catch(err => thunkAPI.rejectWithValue(err.response)),
);
