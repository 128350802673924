import { envData } from '@/library/utils/environmentUtils';
import { callExtJSAPI } from '@/library/utils/extJSUtils';

const getUserAuthorisedSitesCount = (userId): Promise<any> => {
  return callExtJSAPI('GRIDDATA', {
    COMPONENT_INFO_TYPE: 'COUNT',
    GRID_NAME: envData.userOrgsGridName,
    GET_ALL_DATABSE_ROWS: true,
    CACHE_REQUEST: false,
    GET_LAST_CACHED: false,
    USER_FUNCTION_NAME: envData.userOrgsGridName,
    SYSTEM_FUNCTION_NAME: 'EWSUSR',
    LOV_ALIAS_NAME_1: 'parameter.userid',
    LOV_ALIAS_VALUE_1: userId,
    LOV_ALIAS_TYPE_1: 'text',
  });
};

const getUserAuthorisedSitesPagesRequest = (
  userId,
  pageNumber: number,
): Promise<any> => {
  return callExtJSAPI('GRIDDATA', {
    GRID_NAME: envData.userOrgsGridName,
    REQUEST_TYPE: 'LIST.DATA_ONLY.STORED',
    GET_ALL_DATABSE_ROWS: 'true',
    LOV_ALIAS_NAME_1: 'parameter.userid',
    LOV_ALIAS_VALUE_1: userId,
    LOV_ALIAS_TYPE_1: 'text',
    CURSOR_POSITION: (pageNumber - 1) * 100 + 1,
    NUMBER_OF_ROWS_FIRST_RETURNED: 100,
  });
};

export const getWorkOrderTypeToScreenIdMappingRequest = (): Promise<any> => {
  return callExtJSAPI('GRIDDATA', {
    GRID_NAME: envData.workOrderTypeToScreenIdMapGridName,
    REQUEST_TYPE: 'LIST.DATA_ONLY.STORED',
    GET_ALL_DATABSE_ROWS: 'true',
  });
};

export const getUserAuthorisedSites = (userId): Promise<any> => {
  return Promise.all([
    getUserAuthorisedSitesPagesRequest(userId, 1),
    getUserAuthorisedSitesCount(userId),
  ]).then(responses => {
    const [pageData, sitesCountResponse] = responses;

    /*
     * APM returns the count number in a localised form
     * ex: "10,368" for US, "10.369" for Europe
     * Localisation cannot be disabled on APM's COUNT API, so parse the site count by removing all dots and commas
     * */
    const totalSitesCount = parseInt(
      sitesCountResponse.data.responseData.pageData.grid.GRIDRESULT.GRID[
        'TOTAL-COUNT'
      ].replaceAll(/[,.]/g, ''),
    );
    const listOfSites = [
      ...pageData.data.responseData.pageData.grid.GRIDRESULT.GRID.DATA,
    ];
    const totalPagesCount = Math.ceil(totalSitesCount / 100);
    if (totalPagesCount > 1) {
      return Promise.all(
        [...Array(totalPagesCount - 1).keys()].map(index => {
          return getUserAuthorisedSitesPagesRequest(userId, index + 2);
        }),
      ).then(responses => {
        responses.forEach(pageData => {
          listOfSites.push(
            ...pageData.data.responseData.pageData.grid.GRIDRESULT.GRID.DATA,
          );
        });
        return {
          totalSitesCount,
          listOfSites,
        };
      });
    } else {
      return {
        totalSitesCount,
        listOfSites,
      };
    }
  });
};
