import { RelativeRoutingType, To, useNavigate } from 'react-router-dom';

interface iOptions {
  replace?: boolean;
  state?: any;
  relative?: RelativeRoutingType;
}

export function useCustomNavigate(): any {
  const navigate = useNavigate();
  return {
    to: (path: To, options?: iOptions) => navigate(path, options),
    replace: (path: To, options?: iOptions) =>
      navigate(path, { replace: true, ...options }),
    back: (delta: number) => navigate(delta),
  };
}
