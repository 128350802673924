import React, { ReactElement } from 'react';

import PolarisButton, {
  ButtonProps,
} from '@amzn/awsui-components-react/polaris/button';

import { recordClickEvent } from '@/library/utils/metricsUtils';

export interface iButtonProps extends ButtonProps {
  metricId?: string;
}

export function Button(props: iButtonProps): ReactElement {
  const { onClick, metricId } = props;
  const onClickButton = (e): void => {
    onClick?.(e);
    if (metricId) {
      recordClickEvent(metricId);
    }
  };

  return (
    <PolarisButton
      {...props}
      onClick={onClickButton}
    />
  );
}
