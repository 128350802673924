import React, { ReactElement, useCallback, useState } from 'react';

import { TokenGroup } from '@amzn/awsui-components-react';

import Button from '@/library/components/atoms/Button';
import Input from '@/library/components/atoms/Input';
import Modal from '@/library/components/atoms/Modal';
import MultiSelectDropdown from '@/library/components/atoms/MultiSelectDropdown';
import { uiStrings } from '@/library/constants/uiStrings';

import './overwriteReasonModalStyles.scss';

interface iOverwriteReturnParam {
  label: string;
  value: string;
}

const maximumCharactersForCustomReason = 300;

interface iOverwriteReasonModalProps {
  triggerButton: (displayModal: (value: any) => void) => ReactElement;
  onOverwriteValue: (
    param: iOverwriteReturnParam,
    selectedReasons: string[],
    comment: string,
  ) => any;
  overwriteFieldLabel?: string;
  woId?: string | number;
  headingText?: string;
  currentValue?: string;
  recommendedValue?: string;
  overwriteReasons: string[];
}

export function OverwriteReasonModal(
  props: iOverwriteReasonModalProps,
): ReactElement {
  const {
    triggerButton,
    onOverwriteValue,
    overwriteFieldLabel = '',
    woId = '',
    headingText,
    currentValue,
    overwriteReasons,
  } = props;

  const formattedOverwriteReasons = overwriteReasons.map(reason => ({
    label: reason,
    value: reason,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [selectedOverwriteReasons, setSelectedOverwriteReasons] = useState<
    Array<Record<string, string>>
  >([]);
  const [overwriteReasonComment, setOverwriteReasonComment] = useState('');

  const toggleModal = (flag: boolean): void => {
    if (!flag) {
      setSelectedOverwriteReasons([]);
      setOverwriteReasonComment('');
    }
    setModalVisible(flag);
  };

  const displayModal = useCallback(
    (newValue: string): void => {
      if (currentValue !== newValue) {
        setNewValue(newValue);
        toggleModal(true);
      }
    },
    [currentValue],
  );

  const dismissModal = useCallback((): void => toggleModal(false), []);

  const onOverwriteConfirm = (): void => {
    onOverwriteValue(
      { label: newValue, value: newValue },
      selectedOverwriteReasons.map(reason => reason.label),
      overwriteReasonComment,
    );
    toggleModal(false);
  };
  const handleOverwriteReasonChange = useCallback(
    ({ detail: { selectedOptions } }): void => {
      selectedOptions.length <= 3 &&
        setSelectedOverwriteReasons(selectedOptions);
      !selectedOptions.find(reason => reason.value === 'Other') &&
        setOverwriteReasonComment('');
    },
    [],
  );

  const handleCommentChange = useCallback(({ detail: { value } }): void => {
    value.length <= maximumCharactersForCustomReason &&
      setOverwriteReasonComment(value);
  }, []);

  return (
    <div className="overwrite-reason-container">
      <Modal
        visible={modalVisible}
        onDismiss={dismissModal}
        header={
          headingText ??
          `${uiStrings.overwrite} ${overwriteFieldLabel} [${uiStrings.workOrderAbbreviation} #${woId}]`
        }
        footer={
          <div className="overwrite-modal-footer">
            <Button
              variant="link"
              onClick={dismissModal}
            >
              {uiStrings.close}
            </Button>
            <Button
              variant="primary"
              onClick={onOverwriteConfirm}
              disabled={
                selectedOverwriteReasons.find(
                  reason => reason.value === 'Other',
                )
                  ? !overwriteReasonComment
                  : !selectedOverwriteReasons.length
              }
            >
              {uiStrings.overwrite}
            </Button>
          </div>
        }
      >
        <div className="overwrite-modal-content">
          <div>
            <p className="input-label">
              {uiStrings.selectReasonForOverwriting} ({uiStrings.required})
            </p>
            <div className="overwrite-reason-dropdown">
              <MultiSelectDropdown
                selectedOptions={selectedOverwriteReasons}
                options={formattedOverwriteReasons}
                onChange={handleOverwriteReasonChange}
                placeholder={uiStrings.selectMaxThreeReasons}
              />
            </div>
            <div>
              <TokenGroup
                items={selectedOverwriteReasons.map(reason => ({
                  label:
                    reason.label?.length > 20
                      ? `${reason.label.slice(0, 20)}...`
                      : reason.label,
                  dismissLabel: reason.label,
                }))}
                onDismiss={e => {
                  const updatedReasons = [...selectedOverwriteReasons];
                  updatedReasons.splice(e.detail.itemIndex, 1);
                  handleOverwriteReasonChange({
                    detail: {
                      selectedOptions: updatedReasons,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div
            className={
              'overwrite-comment ' +
              (selectedOverwriteReasons.find(reason => reason.value === 'Other')
                ? 'expand'
                : '')
            }
          >
            <p className="input-label">
              {uiStrings.comments} ({uiStrings.required})
            </p>
            <Input
              value={overwriteReasonComment}
              onChange={handleCommentChange}
              textArea
              placeholder={uiStrings.enterReasonForOverwriting}
              autoFocus
            />
            <p>
              {overwriteReasonComment.length}/{maximumCharactersForCustomReason}
            </p>
          </div>
        </div>
      </Modal>
      {triggerButton(displayModal)}
    </div>
  );
}
