export const underUtilisedThreshold = 60;
export const overUtilisedThreshold = 110;

export const isLaborOverUtilized = (utilization): boolean =>
  utilization >= overUtilisedThreshold && utilization >= 0;
export const isLaborUnderUtilized = (utilization): boolean =>
  utilization <= underUtilisedThreshold && utilization >= 0;

export const generateCSVDataForRecommendedChanges = (
  tableData,
  isOverUtilised,
): Record<string, any> => {
  return tableData.map(row => ({
    Recommended: row.recommended
      ? isOverUtilised
        ? 'Remove'
        : 'Recommended'
      : '',
    'Work Order ID': row.woID,
    'Sch. Start Date': row.startDate,
    'Work Order Description': row.woDesc,
    'Equipment Description': row.equipmentDesc,
    'Precedence Score': row.precedenceScore,
    'Est. Labor': row.estLabor,
  }));
};

export const sortRecommendedWOsList = (
  woList,
  hoursToAdjust,
  isOverUtilized,
): typeof woList => {
  let remainingHoursToAdjust = hoursToAdjust;

  const workOrdersList = woList
    .filter((woData: any) => !!woData.work_order_id)
    .map((woData: any) => {
      return {
        woID: parseInt(woData.work_order_id),
        startDate: woData.schedule_start_date,
        woDesc: woData.workorder_description,
        equipmentDesc: woData.equipment_description,
        tacRequirement: woData.tac_requirement,
        precedenceScore: parseFloat(
          parseFloat(woData.precedence_score).toFixed(2),
        ),
        estLabor: parseFloat(parseFloat(woData.estimated_labor).toFixed(2)),
        recommended: false,
      };
    });

  const pre = isOverUtilized ? 1 : -1;
  const post = -pre;

  let woWithValidPrecedenceAndValidEstLabor: Array<typeof workOrdersList> = [];
  let woWithInvalidPrecedenceAndValidEstLabor: Array<typeof workOrdersList> =
    [];
  let woWithValidPrecedenceAndInvalidEstLabor: Array<typeof workOrdersList> =
    [];
  let woWithInvalidPrecedenceAndInvalidEstLabor: Array<typeof workOrdersList> =
    [];

  workOrdersList.forEach(wo => {
    if (isNaN(wo.precedenceScore) && isNaN(wo.estLabor)) {
      woWithInvalidPrecedenceAndInvalidEstLabor.push(wo);
    } else if (!isNaN(wo.precedenceScore) && !isNaN(wo.estLabor)) {
      woWithValidPrecedenceAndValidEstLabor.push(wo);
    } else if (!isNaN(wo.precedenceScore) && isNaN(wo.estLabor)) {
      woWithValidPrecedenceAndInvalidEstLabor.push(wo);
    } else if (isNaN(wo.precedenceScore) && !isNaN(wo.estLabor)) {
      woWithInvalidPrecedenceAndValidEstLabor.push(wo);
    }
  });

  const sortCallback = (a, b): number => {
    if (a.precedenceScore === b.precedenceScore) {
      return a.woID > b.woID ? post : pre;
    }
    return a.precedenceScore > b.precedenceScore ? pre : post;
  };

  woWithValidPrecedenceAndValidEstLabor =
    woWithValidPrecedenceAndValidEstLabor.sort(sortCallback);

  woWithInvalidPrecedenceAndValidEstLabor =
    woWithInvalidPrecedenceAndValidEstLabor.sort(sortCallback);

  woWithValidPrecedenceAndInvalidEstLabor =
    woWithValidPrecedenceAndInvalidEstLabor.sort(sortCallback);

  woWithInvalidPrecedenceAndInvalidEstLabor =
    woWithInvalidPrecedenceAndInvalidEstLabor.sort(sortCallback);

  return [
    ...woWithValidPrecedenceAndValidEstLabor,
    ...woWithInvalidPrecedenceAndValidEstLabor,
    ...woWithValidPrecedenceAndInvalidEstLabor,
    ...woWithInvalidPrecedenceAndInvalidEstLabor,
  ].map(data => {
    if (
      remainingHoursToAdjust &&
      remainingHoursToAdjust > 0 &&
      !isNaN(data.estLabor)
    ) {
      remainingHoursToAdjust -= data.estLabor;
      data.recommended = true;
    }
    return data;
  });
};
