import React, { ReactElement, useEffect, useState } from 'react';
import { usePapaParse } from 'react-papaparse';

import { Storage } from 'aws-amplify';

import { FormField, TokenGroup } from '@amzn/awsui-components-react';

import { recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { useAppDispatch } from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button';
import Input from '@/library/components/atoms/Input';
import Spinner from '@/library/components/atoms/Spinner';
import { appConstants } from '@/library/constants/appConstants';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import { updateOttoAppConfig } from '@/library/storeSlices/masterData/masterDataActions';
import { formCSVFileDataFromFormattedAppConfigData } from '@/pages/Administration/administrationUtils';

import TipsAndTricksContainer from './administrationFrames/TipsAndTricksContainer';

import './administrationStyles.scss';

const configFieldLabelsKey = 'configFieldLabels';

function Administration(): ReactElement {
  const [initialFormattedAppConfigData, setInitialFormattedAppConfigData] =
    useState<Record<string, string[]>>({});
  const [updatedAppConfigData, setUpdatedAppConfigData] = useState<any>({});
  const [inputFieldsData, setInputFieldsData] = useState<any>({});
  const [newFieldLabelInputText, setNewFieldLabelInputText] = useState('');
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fieldLabels, setFieldLabels] = useState<Record<string, string>>({});

  const { readRemoteFile, jsonToCSV } = usePapaParse();

  const dispatch = useAppDispatch();

  useEffect(() => {
    initialiseAdminTab();
  }, []);

  const formatData = (csvData): any => {
    const formattedData = {};
    csvData.forEach(data => {
      Object.keys(data ?? {}).forEach(key => {
        if (key !== configFieldLabelsKey) {
          formattedData[key] = formattedData[key] ?? [];
          if (data?.[key] !== '' && data?.[key] != null) {
            formattedData[key].push(data?.[key]);
          }
        }
      });
    });
    return formattedData;
  };

  const initialiseAdminTab = (): void => {
    setIsLoading(true);
    Storage.get(appConstants.ottoAppConfigFileName, {
      level: 'public',
    }).then(url => {
      readRemoteFile(url, {
        complete: (results: any) => {
          try {
            const configFieldLabelsObject = JSON.parse(
              results.data?.[0]?.[configFieldLabelsKey],
            );
            setFieldLabels(configFieldLabelsObject);
          } catch {
            setFieldLabels({});
          }

          setInitialFormattedAppConfigData(formatData(results.data));
          setUpdatedAppConfigData(formatData(results.data));
          setFieldsList(Object.keys(results.data?.[0] ?? {}));
          setIsLoading(false);
        },
        error: () => {
          setIsLoading(false);
        },
        header: true,
        download: true,
      });
    });
  };

  const onSaveAppConfig = (): void => {
    const updatedCSVData = formCSVFileDataFromFormattedAppConfigData({
      ...updatedAppConfigData,
      [configFieldLabelsKey]: [JSON.stringify(fieldLabels)],
    });

    const csv = jsonToCSV(updatedCSVData);
    setIsSaving(true);
    emitMetricOnDataSubmit();
    Storage.put(appConstants.ottoAppConfigFileName, csv)
      .then(() => {
        dispatch(updateOttoAppConfig(updatedCSVData));
        initialiseAdminTab();
      })
      .finally(() => setIsSaving(false));
  };

  const emitMetricOnDataSubmit = (): void => {
    const updatedAppConfigDataDiff: Record<string, any> = {};

    const updatedDataKeys = Object.keys(updatedAppConfigData);
    const initialDataKeys = Object.keys(initialFormattedAppConfigData);
    updatedAppConfigDataDiff.fieldsAdded = updatedDataKeys.filter(
      updatedKey => !initialDataKeys.includes(updatedKey),
    );
    updatedAppConfigDataDiff.fieldsRemoved = initialDataKeys.filter(
      updatedKey => !updatedDataKeys.includes(updatedKey),
    );

    updatedDataKeys.forEach(updatedKey => {
      if (initialFormattedAppConfigData[updatedKey]) {
        const newValues = updatedAppConfigData[updatedKey].filter(
          value => !initialFormattedAppConfigData[updatedKey].includes(value),
        );
        const removedValues = initialFormattedAppConfigData[updatedKey].filter(
          value => !updatedAppConfigData[updatedKey].includes(value),
        );
        if (newValues.length || removedValues.length) {
          updatedAppConfigDataDiff[updatedKey] = {
            newValues,
            removedValues,
          };
        }
      }
    });

    recordEvent(metricNames.administration_updateConfigFile, {
      updatedAppConfigDataDiff,
    });
  };

  const onBadgeSlide = (key, index, slideLeft: boolean): void => {
    const updatedData = JSON.parse(JSON.stringify(updatedAppConfigData));
    updatedData[key][index] =
      updatedAppConfigData[key][slideLeft ? index - 1 : index + 1];
    updatedData[key][slideLeft ? index - 1 : index + 1] =
      updatedAppConfigData[key][index];
    setUpdatedAppConfigData(updatedData);
  };

  const onAddValueInField = (key): void => {
    const updatedData = {
      ...updatedAppConfigData,
    };

    /*
     * Use the following syntax to allow user to add bulk data (comma separated).
     * Array{{<comma separated values here>}}
     * */
    const checkArrayInput = inputFieldsData[key].match(/Array{{(.*?)}}/);

    if (checkArrayInput) {
      const uniqueDataSet = {};
      updatedData[key].push(
        ...checkArrayInput[1]
          .split(',')
          .map(input => input.trim())
          .filter(input => !!input),
      );

      updatedData[key].forEach(value => {
        uniqueDataSet[value] = 1;
      });

      updatedData[key] = Object.keys(uniqueDataSet);
    } else {
      updatedData[key].push(inputFieldsData[key]);
    }

    setUpdatedAppConfigData(updatedData);
    setInputFieldsData({
      ...(inputFieldsData ?? {}),
      [key]: '',
    });
  };

  const inputFormField = (key): ReactElement => {
    const isRemoved = !updatedAppConfigData?.[key];

    return (
      <div
        className={isRemoved ? 'disable-field' : ''}
        key={key}
      >
        <FormField
          label={
            <div className="admin-input">
              {key} - {fieldLabels[key] ?? ''}
            </div>
          }
        >
          <div className="admin-input">
            <Input
              textArea
              value={inputFieldsData?.[key]}
              onChange={e => {
                const updatedInputData = {
                  ...(inputFieldsData ?? {}),
                  [key]: e.detail.value,
                };
                setInputFieldsData(updatedInputData);
              }}
            />
            <Button
              iconName="add-plus"
              variant="primary"
              onClick={() => onAddValueInField(key)}
              disabled={
                !inputFieldsData?.[key] ||
                updatedAppConfigData?.[key]?.includes(inputFieldsData?.[key])
              }
            />
          </div>
          <TokenGroup
            limit={20}
            items={updatedAppConfigData?.[key]?.map((group, index) => ({
              label: (
                <div>
                  {index > 0 && (
                    <Button
                      variant="inline-icon"
                      iconName="angle-left-double"
                      onClick={() => onBadgeSlide(key, index, true)}
                    />
                  )}
                  {`${group}`}
                  {index < updatedAppConfigData?.[key].length - 1 && (
                    <Button
                      variant="inline-icon"
                      iconName="angle-right-double"
                      onClick={() => onBadgeSlide(key, index, false)}
                    />
                  )}
                </div>
              ),
              dismissLabel: `${group}`,
            }))}
            onDismiss={e => {
              const updatedData = {
                ...updatedAppConfigData,
              };
              updatedData[key].splice(e.detail.itemIndex, 1);
              setUpdatedAppConfigData(updatedData);
            }}
          />
        </FormField>
      </div>
    );
  };

  return (
    <div className="admin-form-container">
      {isLoading ? (
        <div className="loader-container">
          <Spinner size="large" />
          <span>{uiStrings.fetchingAppConfiguration}</span>
        </div>
      ) : (
        <>
          <TipsAndTricksContainer />
          {Object.keys(updatedAppConfigData).map(key => inputFormField(key))}
          <hr />
          <h3>{uiStrings.devConfig}</h3>

          <FormField
            label={
              <div className="admin-input">
                {uiStrings.listOfFieldsInConfig}
              </div>
            }
          >
            <div className="admin-input">
              <Input
                value={newFieldLabelInputText}
                onChange={e => setNewFieldLabelInputText(e.detail.value)}
              />
              <Button
                variant="primary"
                iconName="add-plus"
                onClick={() => {
                  const newFieldsList = [...fieldsList];
                  if (!newFieldsList.includes(newFieldLabelInputText.trim())) {
                    newFieldsList.push(newFieldLabelInputText);
                  }
                  setFieldsList(newFieldsList);

                  const updatedData = {
                    ...updatedAppConfigData,
                    [newFieldLabelInputText.trim()]:
                      initialFormattedAppConfigData?.[
                        newFieldLabelInputText.trim()
                      ] ?? [],
                  };
                  setUpdatedAppConfigData(updatedData);

                  setNewFieldLabelInputText('');
                }}
                disabled={
                  !newFieldLabelInputText ||
                  fieldsList.includes(newFieldLabelInputText)
                }
              />
            </div>
            <TokenGroup
              items={fieldsList.map(key => ({
                label: `${key}`,
                dismissLabel: `${key}`,
              }))}
              onDismiss={e => {
                const updatedData = {
                  ...updatedAppConfigData,
                };
                delete updatedData[fieldsList[e.detail.itemIndex]];
                setUpdatedAppConfigData(updatedData);

                const newFieldsList = [...fieldsList];
                newFieldsList.splice(e.detail.itemIndex, 1);
                setFieldsList(newFieldsList);
              }}
            />
          </FormField>

          <FormField
            label={
              <div className="admin-input">{uiStrings.configFieldLabels}</div>
            }
          >
            {fieldsList.map(key =>
              key === configFieldLabelsKey ? null : (
                <div
                  className="admin-input"
                  key={key}
                >
                  <span className="key-label">{key}</span>

                  <span className="key-label-input">
                    <Input
                      value={fieldLabels[key] ?? ''}
                      onChange={e => {
                        const fieldLabelsUpdatedObject = { ...fieldLabels };
                        fieldLabelsUpdatedObject[key] = e.detail.value;
                        setFieldLabels(fieldLabelsUpdatedObject);
                      }}
                    />
                  </span>
                </div>
              ),
            )}
          </FormField>

          <div className="admin-button-container">
            <Button
              onClick={initialiseAdminTab}
              disabled={isSaving}
            >
              {uiStrings.reset}
            </Button>
            <Button
              variant="primary"
              onClick={onSaveAppConfig}
              loading={isSaving}
            >
              {uiStrings.save}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default Administration;
