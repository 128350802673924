import React, { ReactElement } from 'react';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import Tooltip from '@/library/components/molecules/Tooltip';
import EditWOSessionManager from '@/library/components/organisms/EditWOSessionManager';
import { uiStrings } from '@/library/constants/uiStrings';

interface iInlineOverwriteTriggerButtonProps {
  children: ReactElement;
  value?: string;
}

export function InlineOverwriteTriggerButton(
  props: iInlineOverwriteTriggerButtonProps,
): ReactElement {
  const { children, value } = props;

  const { isEditSessionActive } = useAppSelector(
    state => state.scheduleWODataReducer,
  );

  return isEditSessionActive ? (
    children
  ) : (
    <Tooltip
      content={
        <>
          <p className="start-session-instructions-text">
            {uiStrings.startSessionInstructions}
          </p>
          <EditWOSessionManager buttonVariant="normal" />
        </>
      }
      position="top"
    >
      {!value ? '___' : value}
    </Tooltip>
  );
}
