export const sliceNames = {
  masterData: 'masterData',
  scheduleWOData: 'scheduleWOData',
  overviewData: 'overviewData',
};

export const actionTypes = {
  validateAPMSessionIdAndFetchUserDetails:
    sliceNames.masterData + '/validateAPMSessionIdAndFetchUserDetails',
  fetchUserAuthorisedSites: sliceNames.masterData + '/fetchUserAuthorisedSites',
  fetchWORecommendations: sliceNames.scheduleWOData + '/fetchWORecommendations',
  fetchWorkOrderTypeToScreenIdMappingRequest:
    sliceNames.scheduleWOData + '/fetchWorkOrderTypeToScreenIdMappingRequest',
  fetchWORecommendationsMetaData:
    sliceNames.scheduleWOData + '/fetchWORecommendationsMetaData',
  resetScheduleWOTab: sliceNames.scheduleWOData + '/resetScheduleWOTab',
  startActivityTrackingInActiveSession:
    sliceNames.scheduleWOData + '/startActivityTrackingInActiveSession',
  disableWORecommendationsLoader:
    sliceNames.scheduleWOData + '/disableWORecommendationsLoader',
  resetWOEditSessionTimer:
    sliceNames.scheduleWOData + '/resetWOEditSessionTimer',
  updateSelectedWOs: sliceNames.scheduleWOData + '/updateSelectedWOs',
  sortWORecommendations: sliceNames.scheduleWOData + '/sortWORecommendations',
  fetchAllShiftsForSite: sliceNames.scheduleWOData + '/fetchAllShiftsForSite',
  fetchAllTechniciansForSite:
    sliceNames.scheduleWOData + '/fetchAllTechniciansForSite',
  fetchTechniciansConfigurationForWO:
    sliceNames.scheduleWOData + '/fetchTechniciansConfigurationForWO',
  fetchRecommendationGenerationStatusForSite:
    sliceNames.scheduleWOData + '/fetchRecommendationGenerationStatusForSite',
  editWORecommendation: sliceNames.scheduleWOData + '/editWORecommendation',
  updateAppliedTableFilters:
    sliceNames.scheduleWOData + '/updateAppliedTableFilters',
  startWOEditSession: sliceNames.scheduleWOData + '/startWOEditSession',
  resetFilteredWORecommendationsTableList:
    sliceNames.scheduleWOData + '/resetFilteredWORecommendationsTableList',
  stopWOEditSession: sliceNames.scheduleWOData + '/stopWOEditSession',
  fetchForecastedData: sliceNames.overviewData + '/fetchForecastedData',
  fetchOttoSites: sliceNames.masterData + '/fetchOttoSites',
  updatedSelectedSite: sliceNames.masterData + '/updatedSelectedSite',
  updateOttoAppConfig: sliceNames.masterData + '/updateOttoAppConfig',
  updateWOTablePreferences: sliceNames.masterData + '/updateWOTablePreferences',
  updateScheduleWOMessageBanner:
    sliceNames.scheduleWOData + '/updateScheduleWOMessageBanner',
};
